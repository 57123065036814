import { Theme, useMediaQuery } from '@material-ui/core';
import LogoImg from '../assets/connect-logo-x2.png';

const intrinsicSize = {
	width: 880,
	height: 360,
};

const aspectRatio = intrinsicSize.width / intrinsicSize.height;

export const ConnectLogo: React.ComponentType = () => {
	const isExtraSmall = useMediaQuery<Theme>((theme) =>
		theme.breakpoints.down('xs'),
	);
	const height = isExtraSmall
		? intrinsicSize.height * 0.25
		: intrinsicSize.height * 0.375;
	return (
		<a href="/" style={{ height, width: aspectRatio * height }}>
			<img
				src={LogoImg}
				alt="Remote Social Connect"
				style={{
					width: '100%',
					objectFit: 'contain',
				}}
			/>
		</a>
	);
};
