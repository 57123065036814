import React from 'react';
import {
	ConnectSchedule,
	CreateScheduleParams,
	PublicActivity,
} from '@contracts/connect';
import {
	createStyles,
	Divider,
	Link,
	makeStyles,
	Typography,
} from '@material-ui/core';
import {
	deepDifference,
	deepEquals,
	ensureAtLeastOne,
	ErrorMessage,
	isError,
	isPending,
	LinkButton,
	LoadingButton,
	PageNotFound,
	SlackChannelSelect,
	useSlackChannelSelect,
} from '@common';
import {
	DocumentHead,
	HtmlMessage,
	Loading,
	PageError,
	SchedulePanel,
	ScreenContainer,
	useSchedulePanel,
} from '@common/components';
import {
	ActivitiesMultiselect,
	ActivityContentTile,
	useActivitiesMultiselect,
} from '../../components';
import {
	SchedulesApi,
	useUpdateScheduleRouteState,
	useUpdateScheduleState,
} from './state';
import { routes } from '../../routes';
import {
	ScheduleViewActivityEditor,
	useScheduleViewActivityEditor,
} from '../../components/activity-editors/scheduleViewActivityEditor';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
	return createStyles({
		grid: {
			display: 'grid',
			padding: theme.spacing(4),

			gridTemplateColumns: '7fr 5fr',
			gridTemplateRows: 'auto',
			gap: theme.spacing(4),

			[theme.breakpoints.down('xs')]: {
				gridTemplateColumns: 'auto',
				gap: theme.spacing(2),
				padding: theme.spacing(0),
			},
		},
		leftColumn: {
			order: 1,
			[theme.breakpoints.down('xs')]: {
				order: 2,
				padding: theme.spacing(2),
			},

			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
		imageColumn: {
			order: 2,
			[theme.breakpoints.down('xs')]: {
				order: 1,
				padding: theme.spacing(0),
			},
		},
		existingChannelLink: {
			cursor: 'pointer',
		},
		hash: {
			marginLeft: `0.3em`,
		},
		scheduleContainer: {
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(0, 4, 2, 4),

			gap: theme.spacing(2, 0),
			[theme.breakpoints.down('xs')]: {
				order: 2,
				padding: theme.spacing(2),
			},
		},
		buttonFooter: {
			display: 'grid',
			justifyContent: 'space-between',
			gridAutoFlow: 'column',

			padding: theme.spacing(4),
			gap: theme.spacing(2),

			[theme.breakpoints.down('xs')]: {
				gridAutoFlow: 'row',
				justifyContent: 'stretch',
				padding: theme.spacing(2),

				[`& > div:nth-child(1)`]: {
					gridRow: 2,
				},
				[`& > div:nth-child(2)`]: {
					gridRow: 1,
				},
			},
		},
		buttonsAuto: {
			display: 'grid',
			justifyContent: 'start',

			gridAutoFlow: 'column',
			gridAutoColumns: 'auto',

			gap: theme.spacing(2),
		},
		buttonsEqualizer: {
			display: 'grid',
			justifyContent: 'end',

			gridTemplateColumns: '1fr 1fr',

			gap: theme.spacing(2),
		},
		errorContainer: {
			padding: theme.spacing(0, 4),
		},
	});
});

export const UpdateSchedule: React.ComponentType = () => {
	const result = useUpdateScheduleRouteState();
	if (result.status === 'ok') {
		const { status: _, ...props } = result;
		return <UpdateScheduleContent {...props} />;
	} else if (result.status === 'not-found') {
		return <PageNotFound />;
	} else if (result.status === 'error') {
		return <PageError error={result.error} />;
	} else {
		return <Loading />;
	}
};

type Props = {
	accountId: string;
	integrationId: string;
	activity: PublicActivity;
	schedule: ConnectSchedule;
	billingEnabled: boolean;
	isPro: boolean;
} & SchedulesApi;

const UpdateScheduleContent: React.ComponentType<Props> = ({
	accountId,
	integrationId,
	activity,
	schedule,
	crudRequestState,
	toggleSchedule,
	deleteSchedule,
	updateSchedule,
	billingEnabled,
	isPro,
}) => {
	const history = useHistory();
	const location = useLocation();
	const styles = useStyles();
	const {
		activityId,
		scheduleId,
		otherActivities,
		channelNameRecommendation,
		daysOfWeekRecommendation,
	} = useUpdateScheduleState({
		accountId,
		integrationId,
		activity,
		schedule,
	});

	const { schedule: scheduleTiming, schedulePanelProps } = useSchedulePanel({
		defaultValue: schedule,
	});
	const {
		slackChannel,
		displayListOfSlackChannels,
		slackChannelSelectProps,
	} = useSlackChannelSelect({
		defaultValue: {
			slackChannelId: schedule.slackChannelId,
			name: schedule.slackChannelName,
		},
	});
	const { activityIds: otherActivityIds, activitiesMultiselectProps } =
		useActivitiesMultiselect({
			defaultValue: schedule.activityIds.filter(
				(activity) => activity !== activityId,
			),
		});
	const { editorProps, activityParams } = useScheduleViewActivityEditor({
		activity,
		activityParams: schedule.activityParams,
		mode: 'editing',
	});

	const result = React.useMemo(() => {
		if (scheduleTiming === 'invalid' || !slackChannel) {
			return {
				canSubmit: false,
			} as const;
		}

		const formProps = {
			accountId,
			integrationId,
			scheduleId,
			...scheduleTiming,
			activityIds: ensureAtLeastOne([activityId, ...otherActivityIds]),
			activityParams,
			integrationType: 'slack' as const,
			...(typeof slackChannel === 'object'
				? {
						slackChannelId: slackChannel.slackChannelId,
						slackChannelName: slackChannel.name,
				  }
				: {
						slackChannelId: '',
						slackChannelName: slackChannel,
				  }),
			// these props do not change in the form:
			isActive: schedule.isActive,
		};

		const connectSchedule: CreateScheduleParams = formProps;
		// ignore these props when comparing form generated schedule
		// and BE schedule
		const { status: _, ...diffProps } = schedule;
		return {
			canSubmit: true,
			isDirty: !deepEquals(connectSchedule, diffProps),
			schedule: {
				scheduleId: schedule.scheduleId,
				accountId: schedule.accountId,
				...deepDifference(connectSchedule, diffProps, {
					maxDepth: 0,
				}),
			},
		} as const;
	}, [
		scheduleTiming,
		slackChannel,
		accountId,
		integrationId,
		scheduleId,
		activityId,
		otherActivityIds,
		schedule,
		activityParams,
	]);

	const scheduleIsActive = schedule.isActive;
	const toggle = React.useCallback(() => {
		toggleSchedule(scheduleId, !scheduleIsActive);
	}, [toggleSchedule, scheduleId, scheduleIsActive]);

	const deleteSc = React.useCallback(() => {
		deleteSchedule(scheduleId);
	}, [deleteSchedule, scheduleId]);

	const submit = React.useCallback(() => {
		if (!result.canSubmit) {
			return;
		}
		updateSchedule(result.schedule);
	}, [result.canSubmit, result.schedule, updateSchedule]);

	const goToEditPrompts = React.useCallback(() => {
		history.push(routes.viewPrompts({ location, scheduleId }));
	}, [history, location, scheduleId]);

	return (
		<ScreenContainer variant="two-column">
			<DocumentHead title="Edit Schedule" />
			<div className={styles.grid}>
				<div className={styles.leftColumn}>
					<Typography variant="h2" gutterBottom>
						{activity.name}
					</Typography>
					{activity.descriptionParagraphs.map((para, i) => (
						<Typography variant="body1" paragraph key={i}>
							{para}
						</Typography>
					))}
				</div>
				<div className={styles.imageColumn}>
					<ActivityContentTile activity={activity} />
				</div>
			</div>
			<div className={styles.scheduleContainer}>
				<div>
					<Typography variant="h3" gutterBottom>
						Schedule
					</Typography>
					<Typography variant="body1" paragraph>
						<HtmlMessage message={channelNameRecommendation} />{' '}
						Alternatively, you can choose{' '}
						<Link
							onClick={displayListOfSlackChannels}
							className={styles.existingChannelLink}
						>
							an existing public channel.
						</Link>
					</Typography>
				</div>
				<SlackChannelSelect
					variant="select-or-create"
					{...slackChannelSelectProps}
				/>
				<ScheduleViewActivityEditor {...editorProps} />
				<SchedulePanel
					daysOfWeekRecommendation={daysOfWeekRecommendation}
					{...schedulePanelProps}
				/>
				{otherActivities.length > 0 && (
					<div>
						<Typography variant="h4" gutterBottom>
							Add additional packs
						</Typography>
						<ActivitiesMultiselect
							activities={otherActivities}
							{...activitiesMultiselectProps}
						/>
					</div>
				)}
			</div>

			<ErrorMessage
				error={
					isError(crudRequestState)
						? crudRequestState.error
						: undefined
				}
				className={styles.errorContainer}
			/>
			<Divider />
			<div className={styles.buttonFooter}>
				<div className={styles.buttonsAuto}>
					<LoadingButton
						variant="contained"
						color="primary"
						disabled={isPending(crudRequestState)}
						loading={
							isPending(crudRequestState) &&
							crudRequestState.api === 'delete'
						}
						onClick={goToEditPrompts}
					>
						View/Edit Prompts
					</LoadingButton>
					<LoadingButton
						variant="contained"
						color="secondary"
						disabled={isPending(crudRequestState)}
						loading={
							isPending(crudRequestState) &&
							crudRequestState.api === 'delete'
						}
						onClick={deleteSc}
					>
						Remove schedule
					</LoadingButton>
					<LoadingButton
						variant="outlined"
						color={schedule.isActive ? 'primary' : 'secondary'}
						disabled={isPending(crudRequestState)}
						loading={
							isPending(crudRequestState) &&
							crudRequestState.api === 'toggle'
						}
						onClick={toggle}
					>
						{schedule.isActive
							? 'Pause schedule'
							: 'Resume schedule'}
					</LoadingButton>
				</div>
				<div className={styles.buttonsEqualizer}>
					<LinkButton
						to={routes.explore({ location })}
						variant="outlined"
					>
						Back
					</LinkButton>
					<LoadingButton
						variant="contained"
						color="primary"
						disabled={
							!result.canSubmit ||
							!result.isDirty ||
							isPending(crudRequestState) ||
							(billingEnabled && !isPro)
						}
						loading={
							isPending(crudRequestState) &&
							crudRequestState.api === 'update'
						}
						onClick={submit}
					>
						Save
					</LoadingButton>
				</div>
			</div>
		</ScreenContainer>
	);
};
