import React from 'react';
import { PublicActivity } from '@contracts/connect';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../routes';
import { useConnectStore } from '../../store/connectStore';
import { isError, isSuccess, useCurrentAccount, useSlackStore } from '@common';
import { UseConnectSchedulesApi, useConnectSchedulesApi } from '../../store';
import { useActivityMessages } from '../../utils/useActivityMessages';

export const useCreateScheduleRouteState = () => {
	const { accountSchedulesRequest, publishedActivitiesRequest } =
		useConnectStore();
	const { integrationId, integrationsRequest } = useSlackStore();
	const { currentAccount } = useCurrentAccount();
	const { activityId } = useParams<{ activityId: string }>();
	const accountId = currentAccount?.accountId;

	const activity =
		activityId &&
		publishedActivitiesRequest.data.activities.find(
			(activity) => activity.activityId === activityId,
		);

	const loadedActivities = isSuccess(accountSchedulesRequest);
	const loadedIntegrations = isSuccess(integrationsRequest);

	const error =
		(isError(accountSchedulesRequest) && accountSchedulesRequest.error) ||
		(isError(integrationsRequest) && integrationsRequest.error) ||
		undefined;

	const result = React.useMemo(() => {
		if (activity && integrationId && accountId) {
			return {
				status: 'ok' as const,
				activity,
				accountId,
				integrationId,
			};
		} else if (loadedActivities && loadedIntegrations) {
			return {
				status: 'not-found' as const,
			};
		} else if (error) {
			return {
				status: 'error' as const,
				error,
			};
		} else {
			return {
				status: 'loading' as const,
			};
		}
	}, [
		activity,
		integrationId,
		accountId,
		loadedActivities,
		loadedIntegrations,
		error,
	]);

	return result;
};

type CreateScheduleState = {
	activityId: string;
	otherActivities: PublicActivity[];
	continueToExplore: () => void;
} & Pick<UseConnectSchedulesApi, 'crudRequestState' | 'createSchedule'> &
	ReturnType<typeof useActivityMessages>;

type Props = {
	activity: PublicActivity;
};

export const useCreateScheduleState = ({
	activity,
}: Props): CreateScheduleState => {
	const location = useLocation();
	const { accountActivitiesRequest, publishedActivitiesRequest } =
		useConnectStore();
	const { crudRequestState, createSchedule } = useConnectSchedulesApi();

	const history = useHistory();
	const activityId = activity.activityId;
	const extraActivityIds = activity.extraActivityIds;

	const otherActivities = React.useMemo(() => {
		let activities: PublicActivity[] = [];
		if (extraActivityIds) {
			activities =
				Object.values(accountActivitiesRequest.data)
					.map((accountActivity) => accountActivity)
					.filter(
						(activity) =>
							activity.activityId !== activityId &&
							extraActivityIds.includes(activity.activityId),
					) ||
				Object.values(accountActivitiesRequest.data)
					.map((accountActivity) => accountActivity)
					.filter((activity) => activity.activityId !== activityId);

            /** @deprecated backwards compatibility for existing in-flight connect schedules */
			if (!activities.length) {
				activities =
					publishedActivitiesRequest.data.activities.filter(
						(activity) =>
							activity.activityId !== activityId &&
							extraActivityIds.includes(activity.activityId),
					) ||
					publishedActivitiesRequest.data.activities.filter(
						(activity) => activity.activityId !== activityId,
					);
			}
		}

		return activities;
	}, [
		extraActivityIds,
		accountActivitiesRequest,
		activityId,
		publishedActivitiesRequest,
	]);

	const messages = useActivityMessages(activity);

	const continueToExplore = React.useCallback(() => {
		history.push(routes.explore({ location }));
	}, [history, location]);

	return {
		activityId,
		otherActivities,
		crudRequestState,
		createSchedule,
		continueToExplore,
		...messages,
	};
};
