import {
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import {
	CommonDialog,
	DocumentHead,
	ScreenContainer,
} from '@common/components';
import { useExploreState } from './state';
import {
	ActivityCard,
	ActivityDialogueContent,
	ScheduleList,
} from '../../components';
import { isPending } from '@common/store-tools';
import { ProChip } from '@common/components/proChip';
import React from 'react';

const useStyles = makeStyles((theme) => {
	return createStyles({
		container: {
			padding: theme.spacing(4),
			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(2),
			},
		},
		headingSpacing: {
			paddingBottom: theme.spacing(4),
		},
		activities: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',

			justifyItems: 'space-between',

			gap: theme.spacing(2),
		},
		dialoguePaper: {
			// prevent scroll bar from messing up the
			// list measurements
			overflow: 'hidden',
		},
	});
});

export const Explore: React.ComponentType = () => {
	const styles = useStyles();
	const {
		publishedActivities,
		selectedActivity,
		schedules,
		editSchedule,
		activityPromptsRequest,
	} = useExploreState();
	const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
	const spacing = isXs ? 2 : 4;
	return (
		<ScreenContainer
			variant="two-column"
			containerClassName={styles.container}
		>
			<DocumentHead title="Explore" />
			{schedules.length > 0 && (
				<>
					<Typography variant="h3">Schedules</Typography>
					<ScheduleList
						schedules={schedules}
						editSchedule={editSchedule}
					/>
				</>
			)}
			<Typography variant="h2" gutterBottom>
				Explore <ProChip />
			</Typography>
			<Typography variant="body1" className={styles.headingSpacing}>
				Choose from the below packs to get started. We recommend
				starting with The Water Cooler as it has light conversation
				prompts to get the ball rolling. Once you select a pack, you
				will be able to schedule the cadence in which the prompts are
				delivered to your preferred Slack channel.
			</Typography>
			<Grid container spacing={spacing}>
				{publishedActivities
					.filter((activity) => activity.status !== 'hidden')
					.map((activity) => (
						<Grid item key={activity.activityId} xs={6} md={4}>
							<ActivityCard activity={activity} />
						</Grid>
					))}
			</Grid>
			{selectedActivity && (
				<CommonDialog
					variant="two-column"
					open={true}
					onClose={selectedActivity.unselect}
					className={styles.dialoguePaper}
				>
					<ActivityDialogueContent
						activity={selectedActivity.activity}
						activityPrompts={selectedActivity.prompts}
						loading={isPending(activityPromptsRequest)}
					/>
				</CommonDialog>
			)}
		</ScreenContainer>
	);
};
