import { BaseProviders } from '@remote-social/common/src';
import { SlackStoreProvider } from '@remote-social/common/src/slack';
import { appConfig } from '../appConfig';
import { store } from './reduxStore';
import { ConnectStoreProvider } from './connectStore';
import { createConnectTheme } from '../connectTheme';

const requiredScopes = [
	'chat:write',
	'commands',
	'reactions:read',
	'reactions:write',
	'channels:manage',
	'channels:read',
	'channels:history',
	'channels:join',
	'users.profile:read',
	'users:read',
	'users:read.email',
];

const connectTheme = createConnectTheme();

export const AppProvider: React.ComponentType = ({ children }) => (
	<BaseProviders store={store} appConfig={appConfig} theme={connectTheme}>
		{/* TODO, add more stores, make your pyramid ... */}
		<SlackStoreProvider requiredScopes={requiredScopes}>
			<ConnectStoreProvider>{children}</ConnectStoreProvider>
		</SlackStoreProvider>
	</BaseProviders>
);
