import React from 'react';
import { useAsyncRequest, useCallable, useCurrentAccount } from '@common';
import {
	CreateScheduleParams,
	CreateScheduleResponse,
	UpdateScheduleParams,
} from '@contracts/connect';

export type UseConnectSchedulesApi = ReturnType<typeof useConnectSchedulesApi>;

export const useConnectSchedulesApi = () => {
	const { currentAccount } = useCurrentAccount();
	const accountId = currentAccount?.accountId;
	const deleteSchedule = useCallable('connect-deleteSchedule');
	const updateSchedule = useCallable('connect-updateSchedule');
	const createSchedule = useCallable('connect-createSchedule');
	const [crudRequest, initiate] = useAsyncRequest<
		CreateScheduleResponse | void,
		{ api: 'delete' | 'update' | 'create' | 'toggle' }
	>();

	const api = React.useMemo(() => {
		return {
			crudRequestState: crudRequest,
			deleteSchedule: (scheduleId: string) => {
				if (!accountId) {
					return;
				}
				initiate({
					call: deleteSchedule,
					args: [
						{
							accountId,
							scheduleId,
						},
					],
					api: 'delete',
				});
			},
			updateSchedule: (
				params: Omit<UpdateScheduleParams, 'accountId'>,
			) => {
				if (!accountId) {
					return;
				}
				initiate({
					call: updateSchedule,
					args: [
						{
							accountId,
							...params,
						},
					],
					api: 'update',
				});
			},
			toggleSchedule: (scheduleId: string, isActive: boolean) => {
				if (!accountId) {
					return;
				}
				initiate({
					call: updateSchedule,
					args: [
						{
							accountId,
							scheduleId,
							isActive,
						},
					],
					api: 'toggle',
				});
			},
			createSchedule: (
				params: Omit<CreateScheduleParams, 'accountId'>,
			) => {
				if (!accountId) {
					return;
				}
				initiate({
					call: createSchedule,
					args: [
						{
							accountId,
							...params,
						},
					],
					api: 'create',
				});
			},
		};
	}, [
		accountId,
		createSchedule,
		crudRequest,
		initiate,
		deleteSchedule,
		updateSchedule,
	]);

	return api;
};
