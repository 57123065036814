import { PublicActivity } from '@contracts/connect';
import { routes } from '../routes';
import { Link, useLocation } from 'react-router-dom';
import { ActivityCardTile } from './activityCardTile';

type Props = {
	className?: string;
	activity: PublicActivity;
};

export const ActivityCard: React.ComponentType<Props> = ({
	className,
	activity,
}) => {
	const location = useLocation();
	return (
		<Link
			to={routes.exploreActivity({
				activityId: activity.activityId,
				location,
			})}
			className={className}
		>
			<ActivityCardTile activity={activity} />
		</Link>
	);
};
