import React from 'react';
import { useParams } from 'react-router-dom';
import { useConnectStore } from '../../store/connectStore';
import { useBackendFunction } from '@common/hooks';
import { useCurrentAccount } from '@common/contexts';
import { isError, isPending, isSuccess } from '@common/store-tools';
import { ErrorType } from '@common/errors';
import {
	PromptStatsResponse,
	PublicActivity,
	PublicPrompt,
} from '@contracts/connect';
import { ensureExists } from '@common';

type EditPropsRouteState =
	| {
			status: 'ok';
			accountId: string;
			activity: PublicActivity;
			prompts: PublicPrompt[];
			promptStats: PromptStatsResponse[];
			scheduleId: string;
			editPromptModalMap: Record<string, boolean>;
			toggleEditPromptModal: (
				promptId: string,
				shouldToggle: boolean,
			) => void;
			onEditedPrompt: (updatedPrompt: PublicPrompt) => void;
			reorderPrompt: (promptId: string, order: number) => void;
			updatePromptPending: boolean;
	  }
	| { status: 'not-found' }
	| { status: 'error'; error: ErrorType }
	| { status: 'loading' };

export const useViewPromptsRouteState = (): EditPropsRouteState => {
	const { accountSchedulesRequest, accountActivitiesRequest } =
		useConnectStore();
	const { currentAccount } = useCurrentAccount();
	const { scheduleId } = useParams<{ scheduleId: string }>();
	const [prompts, setPrompts] = React.useState<PublicPrompt[]>([]);

	const [editPromptModalMap, setEditPromptModalMap] = React.useState<
		Record<string, boolean>
	>({});

	const toggleEditPromptModal = React.useCallback(
		(promptId: string, shouldToggle: boolean) => {
			setEditPromptModalMap((curr: Record<string, boolean>) => ({
				...curr,
				[promptId]: shouldToggle,
			}));
		},
		[],
	);

	const accountId = currentAccount?.accountId;

	const accountsSchedulesPending = isPending(accountSchedulesRequest);

	const schedule = scheduleId
		? accountSchedulesRequest.data.schedules.find(
				(schedule) => schedule.scheduleId === scheduleId,
		  )
		: undefined;

	const activityId = schedule?.activityIds[0] || '';

	const activity = activityId
		? Object.values(accountActivitiesRequest.data)?.find(
				(activity) => activity.activityId === activityId,
		  )
		: undefined;

	const [listPrompts] = useBackendFunction('connect-listPrompts', {
		callOnChange: () => {
			if (!accountId || !activityId) {
				return;
			}

			return {
				accountId,
				activityId,
			};
		},
		initialValue: [] as PublicPrompt[],
	});

	const listPromptsSuccess = isSuccess(listPrompts);
	const listPromptsPending = isPending(listPrompts);

	const [listPromptStats] = useBackendFunction('connect-listPromptStats', {
		callOnChange: () => {
			if (!accountId || !prompts || prompts.length === 0) {
				return;
			}

			return {
				accountId,
				promptIds: prompts.map((prompt) => prompt.promptId),
			};
		},
		initialValue: [] as PromptStatsResponse[],
	});

	const promptStats = listPromptStats.data as PromptStatsResponse[];

	React.useEffect(() => {
		if (listPromptsSuccess) {
			const sortedPrompts = (
				(listPrompts.data as PublicPrompt[]) || []
			).sort((a, b) => (a.order || 0) - (b.order || 0));
			setPrompts(sortedPrompts);
		}
	}, [listPrompts.data, listPromptsSuccess]);

	const error =
		(isError(accountSchedulesRequest) && accountSchedulesRequest.error) ||
		(isError(listPrompts) && listPrompts.error) ||
		(isError(listPromptStats) && listPromptStats.error) ||
		undefined;

	const [updatePrompt, updatePromptRequest] = useBackendFunction(
		'connect-updatePrompt',
	);
	const updatePromptPending = isPending(updatePrompt);
	const updatePromptSuccess = isSuccess(updatePrompt);

	const updatedPrompt = React.useMemo(
		() => updatePrompt.data as PublicPrompt | undefined,
		[updatePrompt.data],
	);

	const reorderPrompt = React.useCallback(
		(promptId, order) => {
			if (accountId) {
				updatePromptRequest({
					accountId,
					promptId,
					order,
				});
			}
		},
		[accountId, updatePromptRequest],
	);

	React.useEffect(() => {
		if (updatePromptSuccess && updatedPrompt) {
			onEditedPrompt(updatedPrompt);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatePromptSuccess]);

	const onEditedPrompt = React.useCallback(
		(updatedPrompt: PublicPrompt) => {
			const existingPrompt = ensureExists(
				prompts.find(
					(prompt) => prompt.promptId === updatedPrompt.promptId,
				),
			);
			const adjacentPrompt = prompts.find(
				(prompt) =>
					prompt.promptId !== updatedPrompt.promptId &&
					prompt.order === updatedPrompt.order,
			);
			// @ts-ignore
			const promptsToUpdate = prompts.reduce((acc, prompt, index) => {
				if (updatedPrompt.promptId === prompt.promptId) {
					acc.push(updatedPrompt);
				} else if (
					adjacentPrompt &&
					adjacentPrompt.promptId === prompt.promptId
				) {
					let newOrder: number;
					if (typeof existingPrompt.order === 'number') {
						newOrder = existingPrompt.order;
					} else if (typeof prompt.order === 'number') {
						newOrder = prompt.order;
					} else {
						newOrder = index;
					}
					acc.push({
						...adjacentPrompt,
						order: newOrder,
					});
				} else {
					acc.push({ ...prompt, order: prompt.order || index });
				}

				return acc;
			}, [] as PublicPrompt[]);
			const sortedPrompts = promptsToUpdate.sort(
				(a, b) => (a.order || 0) - (b.order || 0),
			);
			setPrompts(sortedPrompts);
			setEditPromptModalMap((curr: Record<string, boolean>) => ({
				...curr,
				[updatedPrompt.promptId]: false,
			}));
		},
		[prompts],
	);

	return React.useMemo(() => {
		if (
			!listPromptsPending &&
			accountId &&
			prompts &&
			activity &&
			promptStats
		) {
			return {
				status: 'ok' as const,
				prompts,
				promptStats,
				activity,
				scheduleId,
				accountId,
				editPromptModalMap,
				toggleEditPromptModal,
				onEditedPrompt,
				reorderPrompt,
				updatePromptPending,
			};
		} else if (
			!accountsSchedulesPending &&
			!listPromptsPending &&
			(!accountId || !prompts || !activity)
		) {
			return {
				status: 'not-found' as const,
			};
		} else if (error) {
			return {
				status: 'error' as const,
				error,
			};
		} else {
			return {
				status: 'loading' as const,
			};
		}
	}, [
		listPromptsPending,
		accountId,
		prompts,
		activity,
		promptStats,
		accountsSchedulesPending,
		error,
		scheduleId,
		editPromptModalMap,
		toggleEditPromptModal,
		onEditedPrompt,
		reorderPrompt,
		updatePromptPending,
	]);
};
