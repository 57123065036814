import { removeUnsetVariables, setVariables } from '@common/templates';
import { PublicActivity } from '@contracts/connect';
import { DateTime } from 'luxon';
import React from 'react';

function getVariables() {
	const vars = {
		month: DateTime.now().toFormat('MMMM').toLocaleLowerCase(),
		monthYear: DateTime.now().toFormat('MMMM-yyyy').toLocaleLowerCase(),
		dayMonthYear: DateTime.now()
			.toFormat('dd-MMMM-yyyy')
			.toLocaleLowerCase(),
	};
	return vars;
}

function setVariablesForText(text: string) {
	const vars = getVariables();
	return removeUnsetVariables(setVariables(text, vars));
}

export function useActivityMessages(activity: PublicActivity) {
	const defaultChannelName = React.useMemo(
		() =>
			activity.defaultChannelName
				? setVariablesForText(activity.defaultChannelName)
				: 'remote-social-connect',
		[activity.defaultChannelName],
	);

	const channelNameRecommendation = React.useMemo(
		() =>
			activity.channelNameRecommendation
				? setVariablesForText(activity.channelNameRecommendation)
				: `We recommend creating a new public #channel for your schedule.`,
		[activity.channelNameRecommendation],
	);

	const daysOfWeekRecommendation = React.useMemo(
		() =>
			activity.daysOfWeekRecommendation
				? setVariablesForText(activity.daysOfWeekRecommendation)
				: `For better engagement we recommend running 2 - 3 prompts a week.`,
		[activity.daysOfWeekRecommendation],
	);

	return {
		defaultChannelName,
		channelNameRecommendation,
		daysOfWeekRecommendation,
	};
}
