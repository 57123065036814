import React from 'react';
import { CreateScheduleParams, PublicActivity } from '@contracts/connect';
import {
	createStyles,
	Typography,
	makeStyles,
	Link,
	Divider,
} from '@material-ui/core';
import {
	ensureAtLeastOne,
	ErrorMessage,
	ErrorType,
	isPending,
	isSuccess,
	LinkButton,
	LoadingButton,
	PageNotFound,
	SlackChannelSelect,
	useSlackChannelSelect,
} from '@common';
import {
	CommonDialog,
	DocumentHead,
	Loading,
	PageError,
	SchedulePanel,
	ScreenContainer,
	HtmlMessage,
	useSchedulePanel,
} from '@common/components';
import { ActivityContentTile } from '../../components';
import { useCreateScheduleRouteState, useCreateScheduleState } from './state';
import {
	ActivitiesMultiselect,
	useActivitiesMultiselect,
} from '../../components';
import { routes } from '../../routes';
import { ScheduleCreatedDialogueContent } from '../../components/scheduleCreatedContent';
import { ChannelsErrorCodes } from '@contracts/platform';
import {
	ScheduleViewActivityEditor,
	useScheduleViewActivityEditor,
} from '../../components/activity-editors/scheduleViewActivityEditor';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => {
	return createStyles({
		grid: {
			display: 'grid',
			padding: theme.spacing(4),

			gridTemplateColumns: '7fr 5fr',
			gridTemplateRows: 'auto',
			gap: theme.spacing(4),

			[theme.breakpoints.down('xs')]: {
				gridTemplateColumns: 'auto',
				gap: theme.spacing(2),
				padding: theme.spacing(0),
			},
		},
		leftColumn: {
			order: 1,
			[theme.breakpoints.down('xs')]: {
				order: 2,
				padding: theme.spacing(2),
			},

			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
		imageColumn: {
			order: 2,
			[theme.breakpoints.down('xs')]: {
				order: 1,
				padding: theme.spacing(0),
			},
		},
		existingChannelLink: {
			cursor: 'pointer',
		},
		hash: {
			marginLeft: `0.3em`,
		},
		scheduleContainer: {
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(0, 4, 2, 4),

			gap: theme.spacing(2, 0),
			[theme.breakpoints.down('xs')]: {
				order: 2,
				padding: theme.spacing(2),
			},
		},
		buttonFooter: {
			display: 'grid',
			justifyContent: 'end',
			gridAutoFlow: 'column',

			padding: theme.spacing(4),
			gap: theme.spacing(2),

			[theme.breakpoints.down('xs')]: {
				gridAutoFlow: 'row',
				justifyContent: 'stretch',
				padding: theme.spacing(2),
			},
		},
		buttonsEqualizer: {
			display: 'grid',
			justifyContent: 'end',

			gridTemplateColumns: '1fr 1fr',

			gap: theme.spacing(2),
		},
		errorContainer: {
			padding: theme.spacing(0, 4),
		},
	});
});

export const CreateSchedule: React.ComponentType = () => {
	const result = useCreateScheduleRouteState();
	if (result.status === 'ok') {
		const { status: _, ...props } = result;
		return <CreateScheduleContent {...props} />;
	} else if (result.status === 'not-found') {
		return <PageNotFound />;
	} else if (result.status === 'error') {
		return <PageError error={result.error} />;
	} else {
		return <Loading />;
	}
};

function customizeError(error: ErrorType | undefined) {
	if (!error) {
		return error;
	}
	if (error.code === 'invalid-argument') {
		const code = error.details?.code as ChannelsErrorCodes;
		if (code === 'archived-channel') {
			return 'Specified channel is archived, you can manually unarchive it in Slack, or choose a different name';
		} else if (code === 'channel-name-taken') {
			return 'We tried to create a new public channel, but a channel with specified name already exists, it could be a private channel or an archived channel - please choose a different public channel name or try again';
		} else if (code === 'invalid-channel-name') {
			return "Name of the channel you've choosen contains invalid characters";
		}
	}
	return error;
}

type Props = {
	accountId: string;
	integrationId: string;
	activity: PublicActivity;
};

const CreateScheduleContent: React.ComponentType<Props> = ({
	accountId,
	integrationId,
	activity,
}) => {
	const location = useLocation();
	const styles = useStyles();
	const {
		activityId,
		otherActivities,
		crudRequestState,
		createSchedule,
		continueToExplore,
		defaultChannelName,
		channelNameRecommendation,
		daysOfWeekRecommendation,
	} = useCreateScheduleState({
		activity,
	});

	const { schedule: scheduleTiming, schedulePanelProps } = useSchedulePanel({
		...((activity.defaultDaysOfWeek || activity.defaultTimesOfDay) && {
			defaultValue: {
				daysOfWeek: activity.defaultDaysOfWeek,
				timesOfDay: activity.defaultTimesOfDay,
			},
		}),
	});
	const {
		slackChannel,
		displayListOfSlackChannels,
		slackChannelSelectProps,
	} = useSlackChannelSelect({
		defaultValue: defaultChannelName,
	});
	const { activityIds: otherActivityIds, activitiesMultiselectProps } =
		useActivitiesMultiselect();
	const { editorProps, activityParams } = useScheduleViewActivityEditor({
		activity,
		mode: 'creating',
	});

	const requestParams = React.useMemo(() => {
		if (scheduleTiming === 'invalid' || !slackChannel) {
			return {
				canSubmit: false,
			} as const;
		}

		const formProps = {
			accountId,
			integrationId,
			isActive: true,
			...scheduleTiming,
			activityIds: ensureAtLeastOne([activityId, ...otherActivityIds]),
			activityParams,
			integrationType: 'slack' as const,
			...(typeof slackChannel === 'object'
				? {
						slackChannelId: slackChannel.slackChannelId,
				  }
				: {
						slackChannelName: slackChannel,
				  }),
		};
		const connectSchedule: CreateScheduleParams = formProps;
		return {
			canSubmit: true,
			schedule: connectSchedule,
		} as const;
	}, [
		scheduleTiming,
		slackChannel,
		accountId,
		integrationId,
		activityId,
		otherActivityIds,
		activityParams,
	]);

	const submit = React.useCallback(() => {
		if (!requestParams.canSubmit) {
			return;
		}
		createSchedule(requestParams.schedule);
	}, [requestParams.canSubmit, requestParams.schedule, createSchedule]);

	return (
		<ScreenContainer variant="two-column">
			<DocumentHead title="Create Schedule" />
			<div className={styles.grid}>
				<div className={styles.leftColumn}>
					<Typography variant="h2" gutterBottom>
						{activity.name}
					</Typography>
					{activity.descriptionParagraphs.map((para, i) => (
						<Typography variant="body1" paragraph key={i}>
							{para}
						</Typography>
					))}
				</div>
				<div className={styles.imageColumn}>
					<ActivityContentTile activity={activity} />
				</div>
			</div>
			<div className={styles.scheduleContainer}>
				<div>
					<Typography variant="h3" gutterBottom>
						Schedule
					</Typography>
					<Typography variant="body1" paragraph>
						<HtmlMessage message={channelNameRecommendation} />{' '}
						Alternatively, you can choose{' '}
						<Link
							onClick={displayListOfSlackChannels}
							className={styles.existingChannelLink}
						>
							an existing public channel.
						</Link>
					</Typography>
				</div>
				<SlackChannelSelect
					variant="select-or-create"
					{...slackChannelSelectProps}
				/>
				<ScheduleViewActivityEditor {...editorProps} />
				<SchedulePanel
					daysOfWeekRecommendation={daysOfWeekRecommendation}
					{...schedulePanelProps}
				/>
				{otherActivities.length > 0 && (
					<div>
						<Typography variant="h4" gutterBottom>
							Add additional packs
						</Typography>
						<ActivitiesMultiselect
							activities={otherActivities}
							{...activitiesMultiselectProps}
						/>
					</div>
				)}
			</div>

			<ErrorMessage
				error={customizeError(crudRequestState.error)}
				className={styles.errorContainer}
			/>
			<Divider />
			<div className={styles.buttonFooter}>
				<div className={styles.buttonsEqualizer}>
					<LinkButton
						to={routes.explore({ location })}
						variant="outlined"
					>
						Cancel
					</LinkButton>
					<LoadingButton
						variant="contained"
						color="primary"
						disabled={!requestParams.canSubmit}
						loading={isPending(crudRequestState)}
						onClick={submit}
					>
						Create
					</LoadingButton>
				</div>
			</div>
			{isSuccess(crudRequestState) && crudRequestState.api === 'create' && (
				<CommonDialog
					variant="two-column"
					open={true}
					onClose={continueToExplore}
				>
					<ScheduleCreatedDialogueContent
						createScheduleResponse={crudRequestState.data}
						onOkClick={continueToExplore}
					/>
				</CommonDialog>
			)}
		</ScreenContainer>
	);
};
