import React from 'react';
import { Typography } from '@material-ui/core';
import { ActivityEditorProps } from '../editorProps';
import { HireTextField } from './hireTextField';

export const OnboardingActivityEditor: React.ComponentType<ActivityEditorProps> =
	(props) => {
		const onActivityParamsChange = props.onActivityParamsChange;
		const firstHire = props.activityParams?.onboardingHires?.[0] ?? '';
		const onHireChange = React.useCallback(
			(hire: string) => {
				onActivityParamsChange({
					onboardingHires: hire ? [hire] : undefined,
				});
			},
			[onActivityParamsChange],
		);
		return (
			<div>
				<Typography variant="h4" gutterBottom>
					Personalize
				</Typography>
				<Typography variant="body1">
					To personalize this schedule, type in the name of your new
					starter, and we will mention them in Slack.
				</Typography>
				<HireTextField hire={firstHire} onHireChange={onHireChange} />
			</div>
		);
	};
