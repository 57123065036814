import {
	createStyles,
	makeStyles,
	Theme,
	useMediaQuery,
} from '@material-ui/core';
import { PublicActivity } from '@contracts/connect';
import clsx from 'clsx';
import { FluidImgContainer } from '@remote-social/common/src';
import { activityImageDimensionsBySize } from './activityCardTile';

const useStyles = makeStyles((theme) =>
	createStyles({
		image: {
			padding: theme.spacing(0),
		},
		tile: {
			padding: theme.spacing(0),
			borderRadius: theme.spacing(2),
			overflow: 'hidden',
		},
		img: {
			width: `100%`,
			objectFit: 'contain',
		},
	}),
);

type Props = {
	className?: string;
	activity: PublicActivity;
};

export const ActivityContentTile: React.ComponentType<Props> = ({
	className,
	activity,
}) => {
	const styles = useStyles();
	const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
	const variant = isXs ? 'image' : 'tile';

	// uses large tile for both xs and other breakpoints
	// but changes from round corners to be not
	const imageDims = activityImageDimensionsBySize.large;
	const src = activity.largeTileUrl;
	const alt = activity.name;

	return (
		<div className={clsx(styles[variant], className)}>
			<FluidImgContainer {...imageDims}>
				<img className={styles.img} src={src} alt={alt} />
			</FluidImgContainer>
		</div>
	);
};
