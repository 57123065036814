import {
	createStyles,
	makeStyles,
	Theme,
	useMediaQuery,
} from '@material-ui/core';
import { PublicActivity } from '@contracts/connect';
import clsx from 'clsx';
import { FluidImgContainer } from '@remote-social/common/src';

const useStyles = makeStyles((theme) =>
	createStyles({
		tile: {
			padding: theme.spacing(0),
			borderRadius: theme.spacing(2),
			overflow: 'hidden',
		},
		img: {
			width: `100%`,
			objectFit: 'contain',
		},
	}),
);

// these actually only drive aspect ratio
export const activityImageDimensionsBySize = {
	xs: {
		width: 156,
		height: 238,
	},
	large: {
		width: 530,
		height: 466,
	},
};

type Props = {
	className?: string;
	activity: PublicActivity;
};

export const ActivityCardTile: React.ComponentType<Props> = ({
	className,
	activity,
}) => {
	const styles = useStyles();
	const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
	const size = isXs ? 'xs' : 'large';
	// below sizes are used for image aspect ratio
	const imageDims = activityImageDimensionsBySize[size];
	const src = size === 'xs' ? activity.xsTileUrl : activity.largeTileUrl;
	const alt = activity.name;
	return (
		<div className={clsx(styles.tile, className)}>
			<FluidImgContainer {...imageDims}>
				<img className={styles.img} src={src} alt={alt} />
			</FluidImgContainer>
		</div>
	);
};
