import type { SlackUser } from '@contracts/slack';
import React from 'react';
import { isError, RequestState } from '../store-tools';
import { UnreachableError } from '../errors';

export const useSlackUserSelect = (opts?: {
	defaultValue?: string | SlackUser;
}) => {
	const [slackUser, setSlackUser] = React.useState<string | SlackUser>(
		opts?.defaultValue || 'remote-social',
	);

	const inputRef = React.useRef<HTMLInputElement>();

	const displayListOfSlackUsers = React.useCallback(() => {
		if (!inputRef.current) {
			return;
		}
		inputRef.current.focus();
	}, []);

	return {
		slackUser,
		displayListOfSlackUsers,
		slackUserSelectProps: {
			inputRef,
			onChange: setSlackUser,
			value: slackUser,
		},
	};
};

export const determineUserStatus = (
	user: string | SlackUser | undefined,
	request: RequestState<SlackUser[], SlackUser[]>,
):
	| { status: 'unselected'; entry?: undefined }
	| { status: 'not-found'; entry?: undefined }
	| { status: 'selected'; entry: SlackUser }
	| { status: 'unknown'; entry?: undefined } => {
	if (!user) {
		return {
			status: 'unselected',
		};
	}

	const summary = request.data.reduce(
		(result, entry) => {
			if (typeof user === 'object' && user !== null) {
				if (entry.slackUserId !== user.slackUserId) {
					return result;
				}
				return {
					...result,
					byId: entry,
				};
			}

			if (entry.slackUserId === user) {
				return {
					...result,
					byId: entry,
				};
			}

			if (entry.realName === user) {
				return {
					...result,
					byName: result.byName.concat(entry),
				};
			}

			if (
				(entry.realName ?? entry.displayName)
					?.toLocaleLowerCase()
					.includes(user.toLocaleLowerCase())
			) {
				return {
					...result,
					bySearch: result.bySearch.concat(entry),
				};
			}

			return result;
		},
		{
			byId: null as SlackUser | null,
			byName: [] as SlackUser[],
			bySearch: [] as SlackUser[],
		},
	);

	const entry = summary.byId ?? summary.byName.concat(summary.bySearch)[0];

	if (entry) {
		return {
			status: 'selected',
			entry,
		};
	}

	if (isError(request)) {
		return {
			status: 'unknown',
		};
	}

	return {
		status: user ? 'not-found' : 'unselected',
	};
};

export type SlackUserSelectStatus = ReturnType<
	typeof determineUserStatus
>['status'];

export const requestFailedMessage = `The request to list Slack users has failed, please try again later`;

export const helperTextFromStatus = (
	userStatus: SlackUserSelectStatus,
	helperText?: string | Partial<Record<SlackUserSelectStatus, string>>,
) => {
	if (typeof helperText === 'string') {
		return helperText;
	}
	switch (userStatus) {
		case 'unknown':
			return helperText?.[userStatus] ?? requestFailedMessage;
		case 'not-found':
			return (
				helperText?.[userStatus] ?? `Cannot find specified Slack user`
			);
		case 'selected':
			return (
				helperText?.[userStatus] ??
				`Start typing users real name or their Slack display name`
			);
		case 'unselected':
			return (
				helperText?.[userStatus] ??
				`Start typing users real name or their Slack display name`
			);
		default:
			throw new UnreachableError(userStatus);
	}
};
