import React from 'react';
import { PublicActivity, PublicPrompt } from '@contracts/connect';
import { ActivityContentHead } from './activityContentHead';
import { PromptList } from './promptList';

type Props = {
	className?: string;
	activity: PublicActivity;
	activityPrompts: PublicPrompt[];
	loading?: boolean;
};

export const ActivityDialogueContent: React.ComponentType<Props> = ({
	activity,
	activityPrompts,
	loading,
}) => {
	return (
		<>
			<ActivityContentHead
				activity={activity}
				activityPrompts={activityPrompts}
			/>
			<PromptList prompts={activityPrompts} loading={loading} />
		</>
	);
};
