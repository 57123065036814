import {
	ActivityPromptsDoc,
	PublicActivity,
	PublicPrompt,
} from '@contracts/connect';
import { RequestState } from '@remote-social/common/src';
import { useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../routes';
import { ConnectStore, useConnectStore } from '../../store/connectStore';
import { useDocumentPath } from '../../store/helpers';

type ExploreState = {
	publishedActivities: PublicActivity[];
	activityPromptsRequest: RequestState<
		ActivityPromptsDoc,
		ActivityPromptsDoc
	>;
	selectedActivity?: {
		activity: PublicActivity;
		prompts: PublicPrompt[];
		unselect: () => void;
	};
	editSchedule: (scheduleId: string) => void;
} & Pick<ConnectStore, 'schedules'>;

export const useExploreState = (): ExploreState => {
	const { schedules, ...store } = useConnectStore();
	const history = useHistory();
	const location = useLocation();
	const { activityId } = useParams<{ activityId: string }>();

	const publishedActivities =
		store.publishedActivitiesRequest.data.activities;

	const publishedActivity = publishedActivities.find(
		(activity) => activity.activityId === activityId,
	);

	const activityPromptsRequest = useDocumentPath(
		publishedActivity && {
			path: ['connect_activity_prompts', publishedActivity.activityId],
		},
		{
			default: {
				activityId,
				prompts: [] as PublicPrompt[],
			},
		},
	);

	const unselect = useCallback(() => {
		history.push(routes.explore({ location }));
	}, [history, location]);

	const editSchedule = useCallback(
		(scheduleId: string) => {
			history.push(
				routes.schedulesEdit({
					scheduleId,
					location,
				}),
			);
		},
		[history, location],
	);

	return {
		schedules,
		publishedActivities,
		activityPromptsRequest,
		...(publishedActivity && {
			selectedActivity: {
				activity: publishedActivity,
				prompts: activityPromptsRequest.data.prompts,
				unselect,
			},
		}),
		editSchedule,
	};
};
