import { useBackendFunction } from '../../hooks';

export type UseSlackUsersResult = ReturnType<typeof useSlackUsers>;

export const useSlackUsers = (opts: {
	accountId?: string;
	slackTeamId?: string;
}) => {
	const { accountId, slackTeamId } = opts;

	const [listUsersRequest, listUsers] = useBackendFunction(
		'slack-listUsers',
		{
			callOnChange: () => {
				if (!accountId || !slackTeamId) {
					return;
				}

				return {
					accountId,
					slackTeamId,
					// Have to do this for TypeScript to pickup the right
					// function name/parameter/result instead of string/unknown/unknown
					search: undefined,
				};
			},
			initialValue: [],
		},
	);

	return {
		listUsersRequest,
		listUsers,
	};
};
