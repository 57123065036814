import React from 'react';
import {
	createStyles,
	Divider,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { PublicActivity, PublicPrompt } from '@contracts/connect';
import { LinkButton } from '@common';
import { ActivityContentTile } from './activityContentTile';
import { Skeleton } from '@material-ui/lab';
import { routes } from '../routes';
import { ProChip } from '@common/components/proChip';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) =>
	createStyles({
		grid: {
			display: 'grid',

			gridTemplateColumns: '7fr 5fr',
			gridTemplateRows: 'auto',
			gap: theme.spacing(4),

			[theme.breakpoints.down('xs')]: {
				gridTemplateColumns: 'auto',
				gap: theme.spacing(2),
			},
		},
		leftColumn: {
			order: 1,
			padding: theme.spacing(4),
			[theme.breakpoints.down('xs')]: {
				order: 2,
				padding: theme.spacing(2),
			},

			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
		imageColumn: {
			order: 2,
			padding: theme.spacing(4),
			[theme.breakpoints.down('xs')]: {
				order: 1,
				padding: theme.spacing(0),
			},
		},
		scheduleButton: {
			marginTop: theme.spacing(2),
		},
		promptsHeader: {
			paddingLeft: theme.spacing(4),
			paddingBottom: theme.spacing(2),

			[theme.breakpoints.down('xs')]: {
				paddingLeft: theme.spacing(2),
			},
		},
	}),
);

type Props = {
	className?: string;
	activity: PublicActivity;
	activityPrompts: PublicPrompt[];
};

/**
 * Non scrollable area of the activity dialogue
 */
export const ActivityContentHead: React.ComponentType<Props> = ({
	activity,
	activityPrompts,
}) => {
	const styles = useStyles();
	const location = useLocation();
	return (
		<>
			<div className={styles.grid}>
				<div className={styles.leftColumn}>
					<Typography variant="h2" gutterBottom>
						{activity.name} <ProChip />
					</Typography>
					{activity.descriptionParagraphs.map((para, i) => (
						<Typography variant="body1" paragraph key={i}>
							{para}
						</Typography>
					))}
					{/* push the rest of the content in the column down */}
					<div style={{ flex: 1 }} />
					{/* would be weird to show 0 prompts */}
					<Typography variant="h6">
						{activityPrompts.length > 0 ? (
							'Prompts'
						) : (
							<Skeleton width={61} />
						)}
					</Typography>
					{activity.promptsSelectionStrategy === 'loop-endlessly' && (
						<Typography variant="body1">∞</Typography>
					)}
					{activity.promptsSelectionStrategy !== 'loop-endlessly' && (
						<Typography variant="body1">
							<>
								{activityPrompts.length > 0 ? (
									activityPrompts.length.toFixed(0)
								) : (
									<Skeleton width={17} />
								)}
							</>
						</Typography>
					)}
					<LinkButton
						variant="contained"
						color="primary"
						fullWidth
						to={routes.scheduleActivity({
							activityId: activity.activityId,
							location,
						})}
						className={styles.scheduleButton}
					>
						Schedule
					</LinkButton>
				</div>
				<div className={styles.imageColumn}>
					<ActivityContentTile activity={activity} />
				</div>
			</div>
			{activityPrompts.length > 0 && (
				<>
					<Typography variant="h4" className={styles.promptsHeader}>
						Prompts
					</Typography>
					<Divider />
				</>
			)}
		</>
	);
};
