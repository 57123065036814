import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import isObject from 'lodash/isObject';

export function deepEquals<T>(a: T, b: T): boolean {
	return isEqual(a, b);
}

function deepDifferenceInternal<T>(
	next: Partial<T>,
	original: T,
	opts: { maxDepth: number } = { maxDepth: Number.MAX_SAFE_INTEGER },
	depth: number = 1,
): Partial<T> {
	const object = next as any;
	const base = original as any;
	return transform(object, (result: any, value, key) => {
		if (!isEqual(value, base[key])) {
			if (Array.isArray(value) || Array.isArray(base[key])) {
				result[key] = value;
			} else if (isObject(value) && isObject(base[key])) {
				if (depth > opts.maxDepth) {
					result[key] = value;
				} else {
					result[key] = deepDifferenceInternal(
						value,
						base[key],
						opts,
						depth + 1,
					);
				}
			} else {
				result[key] = value;
			}
		}
	});
}

export function deepDifference<T>(
	next: Partial<T>,
	original: T,
	opts?: { maxDepth: number },
): Partial<T> {
	return deepDifferenceInternal(next, original, opts);
}
