import { useRef, useEffect, useCallback } from 'react';
import type {
	FixedSizeGrid,
	FixedSizeList,
	ListOnScrollProps,
} from 'react-window';
import throttle from 'lodash/throttle';
import { useCommonDialogueScrollContainer } from './commonDialog';

/**
 * Window Scroller for react-window virtualized lists - it synchronizes
 * the scroll position of the list with the window making it look like we
 * are scrolling through the window instead of scrolling within a fixed height
 * element - this is default expected behavior for mobile devices
 */

// copy-pasted for TypeScript and React Portal support from
// https://github.com/FedericoDiRosa/react-window-scroller
// the default implementation only supports window

const windowScrollPositionKey = {
	y: 'pageYOffset',
	x: 'pageXOffset',
} as const;

const documentScrollPositionKey = {
	y: 'scrollTop',
	x: 'scrollLeft',
} as const;

const getScrollPosition = (axis: 'x' | 'y', container?: HTMLElement | null) =>
	(container && container[documentScrollPositionKey[axis]]) ||
	window[windowScrollPositionKey[axis]] ||
	document.documentElement[documentScrollPositionKey[axis]] ||
	document.body[documentScrollPositionKey[axis]] ||
	0;

type Props = {
	children: (opts: {
		ref: React.MutableRefObject<any>;
		outerRef: React.MutableRefObject<any>;
		style: React.CSSProperties;
		onScroll: (props: ListOnScrollProps) => void;
	}) => React.ReactElement;
	throttleTime?: number;
	isGrid?: boolean;
};

export const WindowScroller: React.ComponentType<Props> = ({
	children,
	throttleTime = 10,
	isGrid = false,
}) => {
	const ref = useRef<FixedSizeList | FixedSizeGrid>(null);
	const outerRef = useRef<HTMLElement | null>(null);

	const container = useCommonDialogueScrollContainer();

	useEffect(() => {
		const handleWindowScroll = throttle(() => {
			const { offsetTop = 0, offsetLeft = 0 } = outerRef.current || {};
			const scrollTop = getScrollPosition('y', container) - offsetTop;
			const scrollLeft = getScrollPosition('x', container) - offsetLeft;

			if (isGrid) {
				ref.current &&
					(ref.current as FixedSizeGrid).scrollTo({
						scrollLeft,
						scrollTop,
					});
			}
			if (!isGrid) {
				ref.current && ref.current.scrollTo(scrollTop);
			}
		}, throttleTime);

		const containerOrWindow = container ?? window;

		containerOrWindow.addEventListener('scroll', handleWindowScroll);
		return () => {
			handleWindowScroll.cancel();
			containerOrWindow.removeEventListener('scroll', handleWindowScroll);
		};
	}, [isGrid, throttleTime, container]);

	const onScroll = useCallback(
		(props) => {
			let {
				scrollLeft,
				scrollTop,
				scrollOffset,
				scrollUpdateWasRequested,
			} = props;
			if (!scrollUpdateWasRequested) return;
			const top = getScrollPosition('y', container);
			const left = getScrollPosition('x', container);
			const { offsetTop = 0, offsetLeft = 0 } = outerRef.current || {};

			scrollOffset += Math.min(top, offsetTop);
			scrollTop += Math.min(top, offsetTop);
			scrollLeft += Math.min(left, offsetLeft);

			const containerOrWindow = container ?? window;

			if (!isGrid && scrollOffset !== top) {
				containerOrWindow.scrollTo(0, scrollOffset);
			}
			if (isGrid && (scrollTop !== top || scrollLeft !== left)) {
				containerOrWindow.scrollTo(scrollLeft, scrollTop);
			}
		},
		[isGrid, container],
	);

	return children({
		ref,
		outerRef,
		style: {
			width: isGrid ? 'auto' : '100%',
			height: '100%',
			display: 'inline-block',
		},
		onScroll,
	});
};
