import { ConnectSchedule, PublicActivity } from '@contracts/connect';
import { ScheduleItem, useScheduleItemStyles } from './scheduleItem';

export type Props = {
	schedules: Array<
		ConnectSchedule & {
			activities: PublicActivity[];
		}
	>;
	editSchedule: (scheduleId: string) => void;
};

export const ScheduleList: React.ComponentType<Props> = (props) => {
	// this CSS grid layout is highly coupled and difficult to
	// maintain in separate files
	const styles = useScheduleItemStyles();
	const { editSchedule } = props;
	return (
		<div className={styles.list}>
			{props.schedules.map((schedule) => (
				<ScheduleItem
					key={schedule.scheduleId}
					schedule={schedule}
					editSchedule={editSchedule}
				/>
			))}
		</div>
	);
};
