const variableRegex = /[$]\([a-z][a-zA-Z0-9]*\)/g;

function allMatchesFor(regex: RegExp, text: string) {
	const results: RegExpExecArray[] = [];
	let match: RegExpExecArray | null = null;
	do {
		match = regex.exec(text);
		if (match) {
			results.push(match);
		}
	} while (match);
	return results;
}

export function hasUnsetVariables(text: string) {
	const variables = allMatchesFor(variableRegex, text);
	return variables.length > 0;
}

export function removeUnsetVariables(
	text: string,
	warningExtraInfo: object = {},
	deps = {
		warn: (...args: Parameters<typeof console.warn>) =>
			console.warn(...args),
	},
) {
	const variables = allMatchesFor(variableRegex, text);
	if (variables.length > 0) {
		deps.warn(`Found ${variables.length} unset variables`, {
			text,
			variables,
			...warningExtraInfo,
		});
	}
	return text.replace(variableRegex, '');
}
