import {
	buildRoute,
	createRoutes,
	routes as common,
} from '@remote-social/common/src/routes';

export const routes = createRoutes({
	home: common.home,

	explore: buildRoute('/explore'),
	exploreActivity: buildRoute(
		'/explore/activity/:activityId',
		(opts: { activityId: string }) => {
			return {
				pathname: `/explore/activity/${opts.activityId}`,
			};
		},
	),

	schedulesEdit: buildRoute(
		'/schedule/:scheduleId',
		(opts: { scheduleId: string }) => {
			return {
				pathname: `/schedule/${opts.scheduleId}`,
			};
		},
	),
	viewPrompts: buildRoute(
		'/schedule/:scheduleId/view-prompts',
		(opts: { scheduleId: string }) => {
			return {
				pathname: `/schedule/${opts.scheduleId}/view-prompts`,
			};
		},
	),
	scheduleActivity: buildRoute(
		'/schedule/create/:activityId',
		(opts: { activityId: string }) => {
			return {
				pathname: `/schedule/create/${opts.activityId}`,
			};
		},
	),

	common,
});
