import React from 'react';
import { ConnectSchedule, PublicActivity } from '@contracts/connect';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useConnectStore } from '../../store/connectStore';
import {
	isError,
	isSuccess,
	useConfigFlag,
	useCurrentAccount,
	useSlackStore,
} from '@common';
import { routes } from '../../routes';
import { UseConnectSchedulesApi, useConnectSchedulesApi } from '../../store';
import { useActivityMessages } from '../../utils/useActivityMessages';

export const useUpdateScheduleRouteState = () => {
	const location = useLocation();
	const {
		accountSchedulesRequest,
		accountActivitiesRequest,
		publishedActivitiesRequest,
	} = useConnectStore();
	const schedulesApi = useConnectSchedulesApi();
	const { integrationId, integrationsRequest } = useSlackStore();
	const history = useHistory();
	const { currentAccount } = useCurrentAccount();
	const { scheduleId } = useParams<{ scheduleId: string }>();

	const billingEnabled = useConfigFlag('billingEnabled').asBoolean();
	const isPro = currentAccount?.tier === 'pro';

	const accountId = currentAccount?.accountId;

	const schedule = scheduleId
		? accountSchedulesRequest.data.schedules.find(
				(schedule) => schedule.scheduleId === scheduleId,
		  )
		: undefined;

	const activityId = schedule?.activityIds[0] || '';

	const accountActivity = activityId
		? Object.values(accountActivitiesRequest.data)?.find(
				(activity) => activity.activityId === activityId,
		  )
		: undefined;

	/** @deprecated backwards compatibility for existing in-flight connect schedules */
	const publishedActivity = activityId
		? publishedActivitiesRequest.data.activities.find(
				(activity) => activity.activityId === activityId,
		  )
		: undefined;

	const activity = accountActivity || publishedActivity || undefined;

	const loadedSchedules = isSuccess(accountSchedulesRequest);
	const loadedActivities = isSuccess(publishedActivitiesRequest);
	const loadedIntegrations = isSuccess(integrationsRequest);

	const updateScheduleStatus =
		schedulesApi.crudRequestState.api === 'update' &&
		schedulesApi.crudRequestState.status;
	const deleteScheduleStatus =
		schedulesApi.crudRequestState.api === 'delete' &&
		schedulesApi.crudRequestState.status;

	const error =
		(isError(accountSchedulesRequest) && accountSchedulesRequest.error) ||
		(isError(publishedActivitiesRequest) &&
			publishedActivitiesRequest.error) ||
		(isError(integrationsRequest) && integrationsRequest.error) ||
		undefined;

	const result = React.useMemo(() => {
		if (activity && schedule && integrationId && accountId) {
			const {
				crudRequestState,
				toggleSchedule,
				deleteSchedule,
				updateSchedule,
			} = schedulesApi;
			return {
				status: 'ok' as const,
				activity,
				schedule,
				accountId,
				integrationId,
				crudRequestState,
				toggleSchedule,
				deleteSchedule,
				updateSchedule,
				billingEnabled,
				isPro,
			};
		} else if (
			loadedSchedules &&
			loadedActivities &&
			loadedIntegrations &&
			!deleteScheduleStatus
		) {
			return {
				status: 'not-found' as const,
			};
		} else if (error) {
			return {
				status: 'error' as const,
				error,
			};
		} else {
			return {
				status: 'loading' as const,
			};
		}
	}, [
		activity,
		schedule,
		integrationId,
		accountId,
		loadedSchedules,
		loadedActivities,
		loadedIntegrations,
		deleteScheduleStatus,
		error,
		schedulesApi,
		billingEnabled,
		isPro,
	]);

	React.useEffect(() => {
		if (deleteScheduleStatus === 'success') {
			history.replace(routes.explore({ location }));
		}
	}, [deleteScheduleStatus, history, location]);

	React.useEffect(() => {
		if (updateScheduleStatus === 'success') {
			history.replace(routes.explore({ location }));
		}
	}, [updateScheduleStatus, history, location]);

	return result;
};

export type SchedulesApi = Pick<
	UseConnectSchedulesApi,
	'crudRequestState' | 'toggleSchedule' | 'deleteSchedule' | 'updateSchedule'
>;

type UpdateScheduleState = {
	activityId: string;
	scheduleId: string;
	otherActivities: PublicActivity[];
} & ReturnType<typeof useActivityMessages>;

type Props = {
	accountId: string;
	integrationId: string;
	activity: PublicActivity;
	schedule: ConnectSchedule;
};

export const useUpdateScheduleState = ({
	activity,
	schedule,
}: Props): UpdateScheduleState => {
	const { accountActivitiesRequest, publishedActivitiesRequest } =
		useConnectStore();

	const scheduleId = schedule.scheduleId;
	const activityId = activity.activityId;
	const extraActivityIds = activity.extraActivityIds;

	const otherActivities = React.useMemo(() => {
		let activities: PublicActivity[] = [];
		if (extraActivityIds) {
			activities =
				Object.values(accountActivitiesRequest.data).filter(
					(activity) =>
						activity.activityId !== activityId &&
						extraActivityIds.includes(activity.activityId),
				) ||
				Object.values(accountActivitiesRequest.data).filter(
					(activity) => activity.activityId !== activityId,
				);

             /** @deprecated backwards compatibility for existing in-flight connect schedules */
			if (!activities.length) {
				activities =
					publishedActivitiesRequest.data.activities.filter(
						(activity) =>
							activity.activityId !== activityId &&
							extraActivityIds.includes(activity.activityId),
					) ||
					publishedActivitiesRequest.data.activities.filter(
						(activity) => activity.activityId !== activityId,
					);
			}
		}

		return activities;
	}, [
		extraActivityIds,
		accountActivitiesRequest,
		activityId,
		publishedActivitiesRequest,
	]);

	const messages = useActivityMessages(activity);

	return {
		activityId,
		otherActivities,
		scheduleId,
		...messages,
	};
};
