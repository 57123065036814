import { createStyles, makeStyles } from '@material-ui/core';
import { AppLayout, Header } from '@common/components';
import { ConnectLogo } from './logo';

import BackgroundPattern from '../assets/connect-background-pattern.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
	createStyles({
		centerContent: {
			padding: theme.spacing(0),

			display: 'grid',

			justifyItems: 'center',
			gridTemplateRows: 'auto 1fr',

			background: [
				`url(${BackgroundPattern}) center`,
				`linear-gradient(137.52deg, #B0E0FC -8.98%, #66C6FE 106.23%) no-repeat`,
			].join(', '),

			[theme.breakpoints.down('xs')]: {
				background: 'none',
			},

			paddingBottom: theme.spacing(4),
		},
		// disabled on xs
		disableBackground: {
			background: 'none',
		},
		headerArea: {
			padding: theme.spacing(4, 0),
			background: theme.palette.common.white,

			// white background overflows a little to the bottom
			height: `120%`,

			justifySelf: 'stretch',

			[theme.breakpoints.down('xs')]: {
				paddingBottom: theme.spacing(2),
			},
		},
		header: {
			display: 'grid',
			placeContent: 'center',
		},
	}),
);

type Props = {
	variant?: 'home' | 'page-header-and-logo';
};

export const ConnectLayout: React.ComponentType<Props> = ({
	// only one page has white background
	variant = 'page-header-and-logo',
	children,
}) => {
	const styles = useStyles();
	switch (variant) {
		case 'home': {
			return (
				<AppLayout
					Header={Header}
					mainContentClassName={clsx(
						styles.centerContent,
						styles.disableBackground,
					)}
				>
					{children}
				</AppLayout>
			);
		}
		case 'page-header-and-logo': {
			return (
				<AppLayout
					Header={Header}
					mainContentClassName={styles.centerContent}
				>
					<div className={styles.headerArea}>
						<div className={styles.header}>
							<ConnectLogo />
						</div>
					</div>
					{children}
				</AppLayout>
			);
		}
	}
};
