import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { PromptStatsResponse, PublicPrompt } from '@contracts/connect';
import clsx from 'clsx';
import { PromptImageTile } from './promptImageTile';
import React from 'react';

const useStyles = makeStyles((theme) =>
	createStyles({
		prompt: {
			display: 'flex',
			flexDirection: 'row',

			alignItems: 'center',

			gap: theme.spacing(2),
		},
		text: {
			flex: 1,
		},
	}),
);

type Props = {
	className?: string;
	prompt: PublicPrompt;
	promptStats?: PromptStatsResponse;
	throttleImageLoad?: boolean;
};

export const Prompt: React.ComponentType<Props> = ({
	className,
	prompt,
	promptStats,
	throttleImageLoad,
}) => {
	const styles = useStyles();

	return (
		<div className={clsx(styles.prompt, className)}>
			{promptStats && (
				<Typography variant="subtitle2">
					Posted at {promptStats.lastPostedAt}
				</Typography>
			)}
			<Typography variant="subtitle1" className={styles.text}>
				{prompt.text}
			</Typography>
			{prompt.imageUrl && (
				<PromptImageTile
					src={prompt.imageUrl}
					alt={prompt.text}
					throttleImageLoad={throttleImageLoad}
				/>
			)}
		</div>
	);
};
