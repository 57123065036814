import { SlackUserSelect } from '@common/slack';
import { SlackUser } from '@contracts/slack';
import React from 'react';

type Props = {
	hire: string;
	onHireChange: (hire: string) => void;
};

const messages = {
	[`not-found`]: `We couldn't find that Slack user but we will still mention them`,
	unselected: `Enter person's name, e.g. Emma T`,
	selected: `We will mention this person in our onboarding messages`,
};

export const HireTextField: React.ComponentType<Props> = ({
	onHireChange,
	hire,
}) => {
	const onChange = React.useCallback(
		(value: string | SlackUser) => {
			if (typeof value === 'string') {
				onHireChange(value);
			} else {
				onHireChange(value.slackUserId);
			}
		},
		[onHireChange],
	);
	return (
		<SlackUserSelect
			value={hire}
			onChange={onChange}
			helperText={messages}
			label="New Starter"
			placeholder="Emma T"
		/>
	);
};
