import { PublicPrompt } from '@contracts/connect';
import { Form, Formik, FormikProps } from 'formik';
import {
	createStyles,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	TextField,
} from '@material-ui/core';
import { ErrorMessage, LoadingButton } from '@common/components';
import React from 'react';
import { PromptImageTile } from './promptImageTile';
import * as Yup from 'yup';
import {
	ErrorType,
	isError,
	isPending,
	isSuccess,
	useBackendFunction,
} from '@common';

const useStyles = makeStyles((theme) => {
	return createStyles({
		promptField: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(1),
		},
		editableWrapper: {
			display: 'flex',
			alignItems: 'center',
			gap: theme.spacing(2),
		},
		editableImage: {},
		editableText: { flex: '1 0 auto' },
		editableTextInput: { width: '100%' },
	});
});

export type FormValues = Pick<PublicPrompt, 'text'>;

type State = {
	formikRef: React.RefObject<FormikProps<FormValues>>;
	savePrompt: (values: { text: string }) => void;
	updatePromptPending: boolean;
	error?: ErrorType;
};

type Props = {
	accountId: string;
	prompt: PublicPrompt;
	onEditedPrompt: (prompt: PublicPrompt) => void;
};

const useState = ({ prompt, accountId, onEditedPrompt }: Props): State => {
	const formikRef = React.useRef<FormikProps<FormValues>>(null);

	const [updatePrompt, updatePromptRequest] = useBackendFunction(
		'connect-updatePrompt',
	);
	const updatePromptPending = isPending(updatePrompt);
	const updatePromptSuccess = isSuccess(updatePrompt);
	const updatePromptError = isError(updatePrompt)
		? updatePrompt.error
		: undefined;

	const updatedPrompt = React.useMemo(
		() => updatePrompt.data as PublicPrompt | undefined,
		[updatePrompt.data],
	);

	const savePrompt = React.useCallback(
		({ text }) => {
			if (accountId) {
				updatePromptRequest({
					accountId,
					promptId: prompt.promptId,
					text,
				});
			}
		},
		[accountId, prompt, updatePromptRequest],
	);

	React.useEffect(() => {
		if (updatePromptSuccess && updatedPrompt) {
			onEditedPrompt(updatedPrompt);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatePromptSuccess]);

	React.useEffect(() => {
		const formik = formikRef.current;
		if (formik) {
			formik.validateForm();
		}
	}, [formikRef]);

	return React.useMemo(
		() => ({
			error: updatePromptError,
			formikRef,
			savePrompt,
			updatePromptPending,
		}),
		[updatePromptError, savePrompt, updatePromptPending],
	);
};

const validationSchema = Yup.object().shape({
	text: Yup.string().min(3, 'Text is too short').required('Text is required'),
});

export const EditPrompt = ({
	accountId,
	prompt,
	onEditedPrompt,
}: Props): JSX.Element => {
	const styles = useStyles();
	const { formikRef, savePrompt, updatePromptPending, error } = useState({
		accountId,
		onEditedPrompt,
		prompt,
	});

	return (
		<Formik
			innerRef={formikRef}
			initialValues={{ text: prompt.text }}
			onSubmit={savePrompt}
			validationSchema={validationSchema}
			validateOnMount={true}
		>
			{({ isValid, values, touched, handleChange, errors, dirty }) => (
				<Form>
					<div className={styles.promptField}>
						<DialogTitle>Edit Prompt</DialogTitle>
						<DialogContent>
							<div className={styles.editableWrapper}>
								<div className={styles.editableText}>
									<TextField
										className={styles.editableTextInput}
										id={`text`}
										variant="outlined"
										name={`text`}
										defaultValue={values.text}
										onChange={handleChange}
										multiline
										rows={3}
										disabled={updatePromptPending}
									/>
									{errors.text && (
										<ErrorMessage error={errors.text} />
									)}
								</div>
								<div className={styles.editableImage}>
									{prompt.imageUrl && (
										<PromptImageTile
											src={prompt.imageUrl}
											alt={prompt.text}
										/>
									)}
								</div>
							</div>
							{error && <ErrorMessage error={error} />}
						</DialogContent>
						<DialogActions>
							<LoadingButton
								variant="contained"
								color="primary"
								disabled={
									!touched ||
									!dirty ||
									!isValid ||
									updatePromptPending
								}
								loading={updatePromptPending}
								type="submit"
							>
								Save
							</LoadingButton>
						</DialogActions>
					</div>
				</Form>
			)}
		</Formik>
	);
};
