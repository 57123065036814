import { PublicPrompt } from '@contracts/connect';
import {
	createAutoMeasuredRow,
	ensureExists,
	JustInTimeAutoMeasureList,
	WindowScroller,
} from '@common';
import { VariableSizeList } from 'react-window';
import { Prompt } from './prompt';
import {
	createStyles,
	Divider,
	makeStyles,
	Theme,
	useMediaQuery,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { useMemo } from 'react';
import clsx from 'clsx';
import Measure from 'react-measure';

// incorrect values can cause scrolling glitches
// when you change these - ensure you scroll to the bottom
// and test scrolling up from bottom to the top slowly
const SPACING_PX = 16; // = 1rem
const PROMPT_HEIGHT = 100;
const TOP_BOTTOM_SPACING = 2;
const DIVIDER = 1;
const ROW_HEIGHT = [
	PROMPT_HEIGHT,
	SPACING_PX * TOP_BOTTOM_SPACING,
	DIVIDER,
].reduce((a, b) => a + b, 0);

const useStyles = makeStyles((theme) =>
	createStyles({
		prompt: {
			padding: theme.spacing(
				TOP_BOTTOM_SPACING,
				4,
				TOP_BOTTOM_SPACING,
				4,
			),
			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(TOP_BOTTOM_SPACING),
			},
		},
		hidden: {
			visibility: 'hidden',
		},
	}),
);

const PromptListRow = createAutoMeasuredRow<{
	styles: ReturnType<typeof useStyles>;
	prompts: PublicPrompt[];
}>(({ index, data, isScrolling, isFirstMeasurement }, ref) => {
	return (
		<div ref={ref}>
			<Prompt
				prompt={ensureExists(data.prompts[index])}
				className={clsx(data.styles.prompt, {
					[data.styles.hidden]: isFirstMeasurement,
				})}
				throttleImageLoad={isScrolling}
			/>
			<Divider />
		</div>
	);
});

type Props = {
	className?: string;
	prompts: PublicPrompt[];
	/**
	 * While prompts are loading we stick do default height
	 * instead of measuring nothingness
	 */
	loading?: boolean;
	listHeight?: number;
};

export const PromptList: React.ComponentType<Props> = ({
	className,
	prompts,
	loading,
	listHeight,
}) => {
	const styles = useStyles();
	const availableHeight = useMemo(() => window.screen.availHeight, []);
	const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
	const useWindowScrolling = isXs;
	const defaultHeight =
		listHeight ?? 10 * ROW_HEIGHT; /* we expect at least 10 prompts */
	const listProps = {
		className: className,
		itemCount: prompts.length,
		estimatedItemSize: ROW_HEIGHT,
		// window scrolling eventually leads to the entire screen being used
		// so use all available height of the window
		height: useWindowScrolling ? availableHeight : defaultHeight,
		width: `100%`,
		itemData: {
			prompts,
			styles,
		},
	};
	const listRef = useRef<HTMLDivElement | null>();
	return useWindowScrolling ? (
		<div>
			{/* needs display block */}
			<WindowScroller>
				{({ ref, outerRef, style, onScroll }) => (
					<JustInTimeAutoMeasureList
						ref={
							ref as React.MutableRefObject<VariableSizeList | null>
						}
						outerRef={outerRef}
						onScroll={onScroll}
						style={style}
						{...listProps}
					>
						{PromptListRow}
					</JustInTimeAutoMeasureList>
				)}
			</WindowScroller>
		</div>
	) : (
		<Measure>
			{({ contentRect }) => {
				const listHeight = loading
					? defaultHeight
					: contentRect.bounds?.height ?? defaultHeight;
				return (
					<JustInTimeAutoMeasureList
						{...listProps}
						height={listHeight}
						outerRef={listRef}
					>
						{PromptListRow}
					</JustInTimeAutoMeasureList>
				);
			}}
		</Measure>
	);
};
