import { PublicActivity } from '@contracts/connect';
import { Theme, useMediaQuery } from '@material-ui/core';
import { usePrefetchImages } from '@remote-social/common/src';
import { ConnectStore } from './connectStore';

function usePrefetchConnectImages(activities: PublicActivity[]) {
	const maxActivityImages = 6;
	const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
	const images = [
		...activities
			.flatMap((activity) => [
				isXs ? activity.xsTileUrl : activity.largeTileUrl,
				activity.smallTileUrl,
			])
			.slice(0, maxActivityImages),
	];
	usePrefetchImages({
		once: true,
		images,
	});
}

export function useConnectStoreEffects(store: ConnectStore) {
	// prefetch activity images and allow the browser to cache them
	// while people watch the intro video
	usePrefetchConnectImages(store.publishedActivitiesRequest.data.activities);
}
