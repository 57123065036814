import React from 'react';
import {
	Button,
	createStyles,
	Divider,
	IconButton,
	makeStyles,
	Typography,
} from '@material-ui/core';
import {
	CommonDialog,
	DocumentHead,
	LinkButton,
	Loading,
	PageError,
	PageNotFound,
	ScreenContainer,
} from '@common/components';
import { useViewPromptsRouteState } from './state';
import { routes } from '../../routes';
import { useLocation } from 'react-router';
import { EditPrompt } from '../../components/editPrompt';
import { PublicPrompt } from '@contracts/connect';
import { Prompt } from '../../components';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => {
	return createStyles({
		grid: {
			display: 'grid',
			padding: theme.spacing(4),

			gridTemplateColumns: '7fr 5fr',
			gridTemplateRows: 'auto',
			gap: theme.spacing(4),

			[theme.breakpoints.down('xs')]: {
				gridTemplateColumns: 'auto',
				gap: theme.spacing(2),
				padding: theme.spacing(0),
			},
		},
		leftColumn: {
			order: 1,
			[theme.breakpoints.down('xs')]: {
				order: 2,
				padding: theme.spacing(2),
			},

			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
		imageColumn: {
			order: 2,
			[theme.breakpoints.down('xs')]: {
				order: 1,
				padding: theme.spacing(0),
			},
		},
		scheduleContainer: {
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(0, 4, 2, 4),

			gap: theme.spacing(2, 0),
			[theme.breakpoints.down('xs')]: {
				order: 2,
				padding: theme.spacing(2),
			},
		},
		buttonFooter: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: theme.spacing(2),
			padding: theme.spacing(4),

			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(2),
			},
		},
		errorContainer: {
			padding: theme.spacing(0, 4),
		},
		prompts: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
		},
		editablePrompt: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			gap: theme.spacing(4),
			borderRadius: theme.spacing(2),
			padding: theme.spacing(1, 2),
			border: '1px solid #ccc',
		},
		editablePromptPrompt: {
			flex: '1 1 auto',
		},
		editablePromptActions: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: theme.spacing(0.5),
		},
		reorderActionBtn: {
			width: '50px',
		},
		editPromptModalDialog: {
			width: `100%`,
		},
	});
});

const isPromptEditable = (prompt: PublicPrompt) => {
	const ignoredCategories = ['generic-onboarding', 'wordle-challenge'];

	return (
		prompt.categories.filter((category) =>
			ignoredCategories.includes(category),
		).length === 0
	);
};

export const ViewPrompts: React.ComponentType = () => {
	const styles = useStyles();
	const location = useLocation();
	const result = useViewPromptsRouteState();

	if (result.status === 'not-found') {
		return <PageNotFound />;
	}

	if (result.status === 'error') {
		return <PageError error={result.error} />;
	}

	if (result.status !== 'ok') {
		return <Loading />;
	}

	const {
		accountId,
		activity,
		prompts,
		promptStats,
		scheduleId,
		editPromptModalMap,
		toggleEditPromptModal,
		onEditedPrompt,
		updatePromptPending,
		reorderPrompt,
	} = result;

	return (
		<ScreenContainer variant="two-column">
			<DocumentHead title="Edit Prompts" />
			<div className={styles.grid}>
				<div className={styles.leftColumn}>
					<Typography variant="h2" gutterBottom>
						{activity.name}
					</Typography>
				</div>
			</div>
			<div className={styles.scheduleContainer}>
				<div>
					<Typography variant="h3" gutterBottom>
						Prompts
					</Typography>
					<div className={styles.prompts}>
						{prompts.map((prompt, index) => {
							const associatedPromptStats = promptStats.find(
								(promptStat) =>
									promptStat.promptId === prompt.promptId,
							);
							return isPromptEditable(prompt) ? (
								<div
									key={prompt.promptId}
									className={styles.editablePrompt}
								>
									<div
										className={styles.editablePromptPrompt}
									>
										<Prompt prompt={prompt} />
									</div>
									<div
										className={styles.editablePromptActions}
									>
										<IconButton
											className={styles.reorderActionBtn}
											color="primary"
											disabled={
												index === 0 ||
												updatePromptPending
											}
											onClick={() =>
												reorderPrompt(
													prompt.promptId,
													index - 1,
												)
											}
										>
											<KeyboardArrowUpIcon fontSize="small" />
										</IconButton>
										<Button
											variant="outlined"
											color="primary"
											disabled={updatePromptPending}
											onClick={() =>
												toggleEditPromptModal(
													prompt.promptId,
													true,
												)
											}
										>
											Edit
										</Button>
										<IconButton
											className={styles.reorderActionBtn}
											color="primary"
											disabled={
												index === prompts.length - 1 ||
												updatePromptPending
											}
											onClick={() =>
												reorderPrompt(
													prompt.promptId,
													index + 1,
												)
											}
										>
											<KeyboardArrowDownIcon fontSize="small" />
										</IconButton>
									</div>
									<CommonDialog
										variant="two-column"
										open={
											editPromptModalMap[
												prompt.promptId
											] || false
										}
										onClose={() =>
											toggleEditPromptModal(
												prompt.promptId,
												false,
											)
										}
										paperClassName={
											styles.editPromptModalDialog
										}
									>
										<EditPrompt
											accountId={accountId}
											prompt={prompt}
											onEditedPrompt={onEditedPrompt}
										/>
									</CommonDialog>
								</div>
							) : (
								<Prompt
									key={prompt.promptId}
									prompt={prompt}
									promptStats={associatedPromptStats}
								/>
							);
						})}
					</div>
				</div>
			</div>
			<Divider />
			<div className={styles.buttonFooter}>
				<LinkButton
					to={routes.schedulesEdit({
						scheduleId,
						location,
					})}
					variant="outlined"
				>
					Back
				</LinkButton>
			</div>
		</ScreenContainer>
	);
};
