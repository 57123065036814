import {
	isPending,
	isSuccess,
	useCurrentAccount,
	useCurrentUser,
} from '@common';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from '../../routes';
import { useConnectStore } from '../../store/connectStore';

type HomeState =
	| {
			loadingState: 'loaded' | 'no-indicator' | 'with-indicator';
			error?: undefined;
	  }
	| {
			loadingState: 'error';
			error: Error;
	  };

export const useHomeState = (): HomeState => {
	const auth = useCurrentUser();
	const currentAccount = useCurrentAccount();
	const connect = useConnectStore();
	const history = useHistory();
	const location = useLocation();

	// if we have at least one schedule in our account
	// we redirect to the explore page
	const atLeastOneSchedule =
		isSuccess(connect.accountSchedulesRequest) &&
		connect.accountSchedulesRequest.data.schedules.length > 0;

	React.useEffect(() => {
		if (atLeastOneSchedule) {
			history.replace(routes.explore({ location }));
		}
	}, [atLeastOneSchedule, history, location]);

	const error = currentAccount.error;

	if (error) {
		return {
			loadingState: 'error',
			error,
		};
	}

	if (!auth.isLoaded) {
		return {
			loadingState: 'no-indicator',
		};
	}

	if (auth.isAuthenticated) {
		// for authenticated users - make them wait until
		// we load their schedules
		if (isPending(connect.accountSchedulesRequest)) {
			return {
				// this can be slow because it waits for accounts
				// to be loaded first
				loadingState: 'with-indicator',
			};
		}
	}

	return {
		loadingState: 'loaded',
	};
};
