import { createRemoteSocialTheme } from '@common/styles';
import { makeHSLA } from '@common/utils/colors';
import { createTheme } from '@material-ui/core';

const white = { h: 255, s: 0, l: 100, a: 1 };
// const logoColor = { h: 202, s: 100, l: 61, a: 1 };
const connectBlue = { h: 210, s: 100, l: 40, a: 1 };

declare module '@material-ui/core/styles/createPalette' {
	export interface Palette {
		statuses: {
			active: string;
			paused: string;
			completed: string;
		};
	}
}

export const createConnectTheme = () => {
	return createTheme(
		createRemoteSocialTheme({
			name: 'connect',
			createPalette: () => ({
				type: 'light',
				primary: {
					main: makeHSLA(connectBlue),
				},
				secondary: {
					main: '#E755A8',
					light: '#F0ACD5',
					dark: '#E20081',
				},
				background: {
					paper: makeHSLA(white),
					default: makeHSLA(white),
				},
				statuses: {
					active: 'hsla(125, 100%, 98%, 1)',
					paused: 'hsla(44, 100%, 95%, 1)',
					completed: 'hsla(205, 100%, 98%, 1)',
				},
			}),
		}),
	);
};
