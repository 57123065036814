import React from 'react';
import {
	Hidden,
	Typography,
	makeStyles,
	Button,
	SvgIcon,
} from '@material-ui/core';
import clsx from 'clsx';
import { ImageBackground } from '@remote-social/common/src/components/imageBackground';
import { CreateScheduleResponse } from '@contracts/connect';
import { ReactComponent as SlackLogo } from '@remote-social/common/src/assets/images/slack-round.svg';
import { ReactComponent as SuccessImg } from '../assets/create-schedule-success.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridAutoColumns: '1fr',
		padding: theme.spacing(1),
	},
	rightColumn: {
		display: 'grid',
		gridAutoFlow: 'row',
		gridAutoRows: 'auto 1fr auto',

		padding: theme.spacing(4, 4, 1, 4),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
		},
	},
	title: {
		marginBottom: theme.spacing(2),
		textAlign: 'center',
	},
	instructions: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),
	},
	buttons: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(2),
		gap: theme.spacing(2),
	},
	contents: {
		display: 'contents',
	},
	channel: {
		whiteSpace: 'nowrap',
		fontWeight: 'bold',
	},
}));

const SlackIcon = () => (
	<SvgIcon component={SlackLogo} viewBox="70 70 130 130" />
);

type Props = {
	className?: string;
	createScheduleResponse: CreateScheduleResponse;
	onOkClick: () => void;
};

export const ScheduleCreatedDialogueContent: React.ComponentType<Props> = ({
	createScheduleResponse: {
		slackChannelLink,
		isNewChannel,
		slackChannelName,
	},
	onOkClick,
	className,
}) => {
	const styles = useStyles();
	return (
		<div className={clsx(styles.root, className)}>
			<Hidden xsDown>
				<ImageBackground>
					<SuccessImg />
				</ImageBackground>
			</Hidden>
			<div className={styles.rightColumn}>
				<Typography className={styles.title} variant="h3">
					Schedule Created
				</Typography>
				<div className={styles.instructions}>
					{isNewChannel ? (
						<>
							<Typography variant="body1" gutterBottom>
								We've just created a schedule for you in a new
								Slack channel{' '}
								<span className={styles.channel}>
									#{slackChannelName}
								</span>{' '}
								.
							</Typography>
							<Typography variant="body1">
								You can now join the channel from Slack and
								start inviting other people.
							</Typography>
						</>
					) : (
						<>
							<Typography variant="body1" gutterBottom>
								We've just created a schedule for you in the
								Slack channel{' '}
								<span className={styles.channel}>
									#{slackChannelName}
								</span>
								.
							</Typography>
							<Typography variant="body1">
								You can now go to the channel in Slack and
								invite other people to join.
							</Typography>
						</>
					)}
				</div>

				<div className={styles.buttons}>
					<Button
						href={slackChannelLink}
						target="_blank"
						rel="noopener noreferrer"
						startIcon={<SlackIcon />}
						variant="contained"
						color="primary"
					>
						{isNewChannel
							? 'Join Slack Channel'
							: 'Go to Slack Channel'}
					</Button>
					<Button onClick={onOkClick} variant="text">
						Continue
					</Button>
				</div>
			</div>
		</div>
	);
};
