import React from 'react';
import clsx from 'clsx';
import {
	ConnectSchedule,
	ConnectScheduleStatus,
	PublicActivity,
} from '@contracts/connect';
import {
	Avatar,
	createStyles,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import {
	formatScheduleDaysOfWeek,
	formatScheduleStatus,
	formatScheduleTimes,
} from '../utils/formatSchedule';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../routes';

// breakpoints at which we change from
// table layout to card layout
const layout = {
	mobile: 'sm',
	desktop: 'md',
} as const;

/**
 * CSS Grid or table is required to line-up columns for desktop layout
 *
 * CSS Grid, unfortunately, has a draw back of not being able to use <a>
 * for the content within entire row as "display: contents"
 * makes the anchor unfocusable.
 *
 * Alternative solution with "display: table" works nicely for
 * the <a>, but has a bigger drawback of having to fix every
 * column's width to a percentage value instead of driving them
 * based on the content - the text overflow doesn't work.
 * Also doesn't center vertically regardless of content type.
 *
 * CSS Grid was chosen, activity names column is going to hold a
 * focusable link and the entire row is going to handle click and touch
 * event for navigation.
 */

export const useScheduleItemStyles = makeStyles((theme) =>
	createStyles({
		list: {
			[theme.breakpoints.up(layout.desktop)]: {
				display: 'grid',
				/* table layout, unnamed grid areas */
				padding: theme.spacing(2, 0, 2, 0),
				gap: theme.spacing(2, 0),

				gridTemplateColumns:
					'auto minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 0.8fr) auto',
				gridAutoFlow: 'row',
				alignItems: 'stretch',

				'& > * > div,a': {
					gridArea: 'unset',
				},
			},

			[theme.breakpoints.down(layout.mobile)]: {
				display: 'grid',

				padding: theme.spacing(2, 0, 2, 0),
				gap: theme.spacing(2, 0),
			},
		},
		active: {
			backgroundColor: theme.palette.statuses.active,
			[theme.breakpoints.up(layout.desktop)]: {
				[`& $col`]: {
					backgroundColor: theme.palette.statuses.active,
				},
			},
		},
		paused: {
			backgroundColor: theme.palette.statuses.paused,
			[theme.breakpoints.up(layout.desktop)]: {
				[`& $col`]: {
					backgroundColor: theme.palette.statuses.paused,
				},
			},
		},
		completed: {
			backgroundColor: theme.palette.statuses.completed,
			[theme.breakpoints.up(layout.desktop)]: {
				[`& $col`]: {
					backgroundColor: theme.palette.statuses.completed,
				},
			},
		},
		tile: {
			position: 'relative',
			cursor: 'pointer',

			[theme.breakpoints.up(layout.desktop)]: {
				// /* remove tile element from layout */
				display: 'contents',

				'& > div:nth-child(1)': {
					borderRadius: theme.spacing(2, 0, 0, 2),
				},
				'& > div:last-child': {
					borderRadius: theme.spacing(0, 2, 2, 0),
					justifyContent: 'flex-end',
				},
			},

			[theme.breakpoints.down(layout.mobile)]: {
				display: 'grid',

				borderRadius: theme.spacing(2),
				padding: theme.spacing(2),
				overflow: 'hidden',

				gridTemplateColumns: 'auto 1fr auto',
				gridTemplateAreas: [
					'activityIcons activityNames arrow',
					'times times times',
					'daysOfWeek daysOfWeek daysOfWeek',
					'status status status',
				]
					.map((row) => `"${row}"`)
					.join(' '),

				'& > div:last-child': {
					justifyContent: 'flex-end',
				},
			},
		},
		col: {
			[theme.breakpoints.up(layout.desktop)]: {
				padding: theme.spacing(2),
				display: 'grid',
				gridAutoFlow: 'row',
				alignItems: 'center',
			},

			[theme.breakpoints.down(layout.mobile)]: {
				display: 'grid',
				gridAutoFlow: 'row',
				alignItems: 'center',
			},
		},
		activityIcon: {
			borderRadius: theme.spacing(1),
		},
		arrowCol: {
			gridArea: 'arrow',
		},
		activitiesNamesCol: {
			gridArea: 'activityNames',
			alignContent: 'center',

			[theme.breakpoints.down(layout.mobile)]: {
				paddingLeft: theme.spacing(2),
			},
		},
		activityIconsCol: {
			gridArea: 'activityIcons',
		},
		timesCol: {
			gridArea: 'times',

			[theme.breakpoints.down(layout.mobile)]: {
				paddingTop: theme.spacing(2),
			},
		},
		daysOfWeekCol: {
			gridArea: 'daysOfWeek',
		},
		statusCol: {
			gridArea: 'status',
			[theme.breakpoints.up(layout.desktop)]: {
				textAlign: 'center',
			},
		},
		activityNamesText: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		noWrap: {
			whiteSpace: 'nowrap',
		},
		anchorOverride: {
			textDecoration: 'none',
			color: 'inherit',

			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	}),
);

function determineStatus(schedule: ConnectSchedule): ConnectScheduleStatus {
	if (!schedule.status) {
		return schedule.isActive ? 'active' : 'paused';
	}
	return schedule.status;
}

export type Props = {
	schedule: ConnectSchedule & {
		activities: PublicActivity[];
	};
	editSchedule: (scheduleId: string) => void;
};

export const ScheduleItem: React.ComponentType<Props> = (props) => {
	const styles = useScheduleItemStyles();
	const location = useLocation();
	const [allTimes, allTimesDescription] = React.useMemo(
		() => formatScheduleTimes(props.schedule),
		[props.schedule],
	);
	const daysOfWeek = React.useMemo(
		() => formatScheduleDaysOfWeek(props.schedule),
		[props.schedule],
	);
	const status = determineStatus(props.schedule);

	const editScheduleProp = props.editSchedule;
	const scheduleId = props.schedule.scheduleId;

	const editSchedule: React.MouseEventHandler<HTMLElement> =
		React.useCallback(
			(e) => {
				e.preventDefault();
				e.stopPropagation();
				editScheduleProp(scheduleId);
			},
			[scheduleId, editScheduleProp],
		);

	return (
		<div
			className={clsx(styles.tile, styles[status])}
			onClick={editSchedule}
		>
			<div className={clsx(styles.col, styles.activityIconsCol)}>
				<AvatarGroup>
					{props.schedule.activities.map((activity) => (
						<Avatar
							key={activity.activityId}
							variant="square"
							className={styles.activityIcon}
							src={activity.smallTileUrl}
							alt={activity.name}
						/>
					))}
				</AvatarGroup>
			</div>
			<Link
				to={routes.schedulesEdit({
					scheduleId,
					location,
				})}
				className={clsx(
					styles.col,
					styles.activitiesNamesCol,
					styles.anchorOverride,
				)}
				onClick={editSchedule}
			>
				<Typography
					variant="subtitle1"
					className={styles.activityNamesText}
				>
					{props.schedule.activities
						.map((activity) => activity.name)
						.join(', ')}
				</Typography>
				<Typography variant="body2">
					{`#${props.schedule.slackChannelName}`}
				</Typography>
			</Link>
			<div className={clsx(styles.col, styles.timesCol)}>
				<Typography variant="body1">
					{allTimesDescription ?? allTimes}
				</Typography>
			</div>
			<div className={clsx(styles.col, styles.daysOfWeekCol)}>
				<Typography variant="body1">on {daysOfWeek}</Typography>
			</div>
			<div className={clsx(styles.col, styles.statusCol)}>
				<Typography variant="body1">
					{formatScheduleStatus(status)}
				</Typography>
			</div>
			<div className={clsx(styles.col, styles.arrowCol)}>
				<ArrowForwardIcon color="action" fontSize="small" />
			</div>
		</div>
	);
};
