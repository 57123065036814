import { PublicActivity } from '@contracts/connect';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
} from '@material-ui/core';
import React from 'react';

export const useActivitiesMultiselect = (opts?: { defaultValue: string[] }) => {
	const [activityIds, setActivityIds] = React.useState<string[]>(
		opts?.defaultValue ?? [],
	);
	return {
		activityIds,
		activitiesMultiselectProps: {
			value: activityIds,
			onChange: setActivityIds,
		},
	};
};

type Props = {
	activities: PublicActivity[];

	value: string[];
	onChange: (selectedActivityIds: string[]) => void;
};

export const ActivitiesMultiselect: React.ComponentType<Props> = React.memo(
	({ onChange: onChangeProp, activities, value: selectedActivityIds }) => {
		// because onCheckBoxChange is shared among all check boxes
		// we do not want them to be rerendered when just a single
		// value changes
		const propsRef = React.useRef(selectedActivityIds);
		propsRef.current = selectedActivityIds;

		const onCheckBoxChange = React.useCallback(
			(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
				const activityId = event.currentTarget.name;
				const wasChecked = propsRef.current.includes(activityId);
				if (wasChecked !== checked) {
					onChangeProp(
						checked
							? [...propsRef.current, activityId]
							: propsRef.current.filter(
									(id) => id !== activityId,
							  ),
					);
				}
			},
			[onChangeProp],
		);

		const state = React.useMemo(() => {
			return activities.map((activity) => ({
				name: activity.activityId,
				label: activity.name,
				checked: selectedActivityIds.includes(activity.activityId),
				onChange: onCheckBoxChange,
			}));
		}, [onCheckBoxChange, activities, selectedActivityIds]);

		return (
			<FormControl component="fieldset">
				<FormGroup row>
					{state.map((entry) => (
						<FormControlLabel
							key={entry.name}
							control={
								<Checkbox
									checked={entry.checked}
									onChange={entry.onChange}
									name={entry.name}
								/>
							}
							label={entry.label}
						/>
					))}
				</FormGroup>
			</FormControl>
		);
	},
);
ActivitiesMultiselect.displayName = 'ActivitiesMultiselect';
