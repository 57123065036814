import { escapeRegExp } from '../utils/escapeRegExp';

export function setVariables(text: string, variables: Record<string, string>) {
	let currentText = text;
	for (const [key, value] of Object.entries(variables)) {
		const varKeyRegExp = new RegExp(`\\$\\(${escapeRegExp(key)}\\)`, 'g');
		currentText = currentText.replace(varKeyRegExp, value);
	}
	return currentText;
}
