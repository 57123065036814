import { useBackendFunction } from '../../hooks';
import React from 'react';

export const useSlackChannels = (opts: {
	accountId?: string;
	integrationId?: string;
	requestOnMount?: boolean;
}) => {
	const { accountId, integrationId } = opts;

	const [listChannelsRequest, listChannels] = useBackendFunction(
		'platform-listChannels',
		{
			callOnChange: () => {
				if (!accountId || !integrationId) {
					return undefined;
				}

				return {
					accountId,
					integrationId,
				};
			},
			initialValue: [],
		},
	);

	return React.useMemo(
		() => ({
			listChannelsRequest,
			listChannels,
		}),
		[listChannelsRequest, listChannels],
	);
};
