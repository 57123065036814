import { ActivityParams } from '@contracts/connect';
import React from 'react';
import { ActivityEditorProps } from './editorProps';
import { OnboardingActivityEditor } from './onboarding/onboardingActivityEditor';

type HookProps = Pick<
	ActivityEditorProps,
	'activity' | 'mode' | 'activityParams'
>;

export function useScheduleViewActivityEditor(props: HookProps) {
	const [activityParams, setActivityParams] = React.useState(
		props.activityParams,
	);
	const onActivityParamsChange = React.useCallback(
		(activityParams: ActivityParams) => {
			setActivityParams((existing) => ({
				...existing,
				...activityParams,
			}));
		},
		[setActivityParams],
	);
	return {
		editorProps: {
			...props,
			activityParams,
			onActivityParamsChange,
		},
		activityParams,
	};
}

/**
 * This editor is injected into ScheduleView when creating or updating the schedule.
 * NOTE: We expect there is going to be an entire page designated to customization
 * of an activity later. Because this solution only scalable to a small degree
 * (1 additional component maximum)
 */
export const ScheduleViewActivityEditor: React.ComponentType<ActivityEditorProps> =
	(props) => {
		if (props.activity.activityId === 'generic-onboarding') {
			return <OnboardingActivityEditor {...props} />;
		}
		return null;
	};
