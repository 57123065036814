import {
	createStyles,
	makeStyles,
} from '@material-ui/core';
import {
	DocumentHead,
	Loading,
	PageError,
} from '@common/components';
import { useHomeState } from './state';

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'grid',
			width: '100%',

			placeItems: 'center',
			gridTemplateRows: 'auto auto auto',
		},
		iframe: {
			border: 'none',
			width: '100%',
			height: '3000px',
			overflow: 'hidden',
			[theme.breakpoints.down('xs')]: {
				height: '4200px',
			},
			[theme.breakpoints.only('sm')]: {
				height: '3200px',
			},
			[theme.breakpoints.up('md')]: {
				height: '3200px',
			},
			"@media (min-width: 480px) and (max-width: 768px)": {
				height: '3700px'
			},
		}
	}),
);

export const Home: React.ComponentType = () => {
	const styles = useStyles();
	const { loadingState, error } = useHomeState();

	if (error) {
		return <PageError error={error} />;
	}
	if (loadingState === 'no-indicator') {
		// do not flash loading indicator
		return null;
	}
	if (loadingState === 'with-indicator') {
		return <Loading />;
	}

	return (
		<div className={styles.container}>
			<DocumentHead title="Virtual Watercooler" />
			<iframe 
				className={styles.iframe}
				title = 'connectHome'
				scrolling = 'no'
				src = 'https://www.remotesocial.io/lp/remote-social-connect-app'
			/>
		</div>
	);
};
