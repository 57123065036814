import React, { useContext } from 'react';
import {
	ensureExists,
	isSuccess,
	isTruthy,
	RequestState,
	useCurrentAccount,
} from '@common';
import {
	AccountActivities,
	AccountSchedulesDoc,
	ActivitiesDoc,
	ConnectSchedule,
	PublicActivity,
} from '@contracts/connect';
import { useDocumentPath } from './helpers';
import { useConnectStoreEffects } from './contextStoreEffects';

export type ConnectStore = {
	accountActivitiesRequest: RequestState<
		AccountActivities,
		AccountActivities
	>;
	publishedActivitiesRequest: RequestState<ActivitiesDoc, ActivitiesDoc>;

	accountId?: string;
	accountSchedulesRequest: RequestState<
		AccountSchedulesDoc,
		AccountSchedulesDoc
	>;
	schedules: Array<
		ConnectSchedule & {
			activities: PublicActivity[];
		}
	>;
};

function joinAccountSchedulesWithActivities(
	accountActivitiesRequest: ConnectStore['accountActivitiesRequest'],
	publishedActivitiesRequest: ConnectStore['publishedActivitiesRequest'],
	accountSchedulesRequest: ConnectStore['accountSchedulesRequest'],
) {
	return {
		schedules:
			(isSuccess(accountSchedulesRequest) &&
				isSuccess(publishedActivitiesRequest) &&
				isSuccess(accountActivitiesRequest) &&
				accountSchedulesRequest.data.schedules.map((schedule) => ({
					...schedule,
					activities: schedule.activityIds
						.map(
							(activityId) =>
								Object.values(accountActivitiesRequest.data)
									.map((accountActivity) => accountActivity)
									.find(
										(activity) =>
											activity.activityId === activityId,
									) ||
								/** @deprecated backwards compatibility for existing in-flight connect schedules */
								publishedActivitiesRequest.data?.activities?.find(
									(activity) =>
										activity.activityId === activityId,
								),
						)
						.filter(isTruthy),
				}))) ||
			[],
	};
}

const Context = React.createContext<ConnectStore | null>(null);

export const ConnectStoreProvider: React.ComponentType = ({ children }) => {
	const {
		currentAccountId: accountId,
		isLoaded,
		needsAccountCreated,
	} = useCurrentAccount();
	const publishedActivitiesRequest = useDocumentPath(
		{
			path: ['connect_published_activities', 'default' as string],
		},
		{
			default: {
				activities: [] as ActivitiesDoc['activities'],
			},
		},
	);

	const accountActivitiesRequest = useDocumentPath(
		accountId &&
			({
				path: [`accounts`, accountId, 'connect_activities'],
			} as any),
		{
			default: {} as AccountActivities,
		},
	) as RequestState<AccountActivities, AccountActivities>;

	const accountSchedulesRequest = useDocumentPath(
		accountId && {
			path: ['connect_schedules', accountId],
		},
		{
			default: {
				accountId: accountId || '',
				// no schedules
				schedules: [],
			},
		},
	);

	const shouldWaitForAccountsLoading = !needsAccountCreated && !isLoaded;

	const value: ConnectStore = React.useMemo(() => {
		return {
			accountActivitiesRequest,
			publishedActivitiesRequest,
			accountId,
			accountSchedulesRequest: {
				...accountSchedulesRequest,
				...(shouldWaitForAccountsLoading && {
					status: 'pending',
				}),
			},
			...joinAccountSchedulesWithActivities(
				accountActivitiesRequest,
				publishedActivitiesRequest,
				accountSchedulesRequest,
			),
		};
	}, [
		accountActivitiesRequest,
		accountId,
		accountSchedulesRequest,
		publishedActivitiesRequest,
		shouldWaitForAccountsLoading,
	]);

	useConnectStoreEffects(value);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useConnectStore() {
	const value = useContext(Context);
	return ensureExists(value);
}
