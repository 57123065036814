import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
	createStyles({
		card: {
			display: 'flex',

			padding: theme.spacing(0),

			borderRadius: theme.spacing(2),
			boxShadow: `none`,

			overflow: 'hidden',

			height: 100,
			width: 100,

			background: theme.palette.common.black,
		},
		img: {
			width: `100%`,
			objectFit: 'cover',
		},
	}),
);

type Props = {
	className?: string;
	src: string;
	alt: string;
	throttleImageLoad?: boolean;
};

export const PromptImageTile: React.ComponentType<Props> = ({
	className,
	src,
	alt,
	throttleImageLoad,
}) => {
	const styles = useStyles();
	return (
		<div className={clsx(styles.card, className)}>
			{!throttleImageLoad && (
				<img
					className={styles.img}
					crossOrigin="anonymous"
					src={src}
					alt={alt}
				/>
			)}
		</div>
	);
};
