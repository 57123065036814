import { ConnectSchedule, ConnectScheduleStatus } from '@contracts/connect';
import {
	DayOfWeek,
	parseTimeOfDay,
	TimeOfDay,
	TimesOfDayType,
} from '@contracts/schedules';
import { ensure, ensureExists, isTruthy, UnreachableError } from '@common';
import { DateTime } from 'luxon';

const dayOfWeekIndex = {
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6,
	sunday: 7,
} as const;

function formatDayOfWeek(day: DayOfWeek) {
	const now = DateTime.now();
	return now
		.set({ weekday: dayOfWeekIndex[day] })
		.toLocaleString({ weekday: 'short' });
}

function formatDaysOfWeek(daysOfWeek: DayOfWeek[]) {
	return daysOfWeek.map((day) => formatDayOfWeek(day)).join(', ');
}

function formatTimeOfDay(time: TimeOfDay) {
	return DateTime.now()
		.startOf('day')
		.plus(parseTimeOfDay(time))
		.toLocaleString(DateTime.TIME_SIMPLE);
}

function formatTimesOfDayList(times: TimeOfDay[]) {
	return times.map((time) => formatTimeOfDay(time)).join(', ');
}

function formatTimesOfDayRange(times: TimeOfDay[]) {
	return [times[0], times[times.length - 1]]
		.filter(isTruthy)
		.map((time) => formatTimeOfDay(time))
		.join(' to ');
}

function formatNumberOfTimes(numberOfTimes: number) {
	ensure(numberOfTimes >= 1);
	if (numberOfTimes === 1) {
		return 'One time';
	} else if (numberOfTimes === 2) {
		return 'Two times';
	} else if (numberOfTimes === 3) {
		return 'Three times';
	} else if (numberOfTimes === 4) {
		return 'Four times';
	} else if (numberOfTimes === 5) {
		return 'Five times';
	} else if (numberOfTimes === 6) {
		return 'Six times';
	} else if (numberOfTimes === 7) {
		return 'Seven times';
	} else if (numberOfTimes === 8) {
		return 'Eight times';
	} else if (numberOfTimes === 9) {
		return 'Nine times';
	} else {
		return `${numberOfTimes.toFixed()} times`;
	}
}

function formatTimesOfDay(
	times: TimeOfDay[],
	timesOfDayType?: TimesOfDayType,
): [string, string?] {
	switch (timesOfDayType) {
		case 'every-hour-on-the-hour': {
			return [formatTimesOfDayRange(times), 'Every hour on the hour'];
		}
		default: {
			if (times.length > 3) {
				// NOTE: This actually won't happen on PROD today
				// and only happens for custom schedules edited manually
				// through API - I've made this change so the UI doesn't
				// look ugly for schedules that trigger every 5 minutes every day
				const numberOfTimes = formatNumberOfTimes(times.length);
				const start = formatTimeOfDay(ensureExists(times[0]));
				const end = formatTimeOfDay(
					ensureExists(times[times.length - 1]),
				);
				const description = `${numberOfTimes} between ${start} and ${end}`;
				return [formatTimesOfDayList(times), description];
			} else {
				return [formatTimesOfDayList(times)];
			}
		}
	}
}

export function formatScheduleTimes(schedule: ConnectSchedule) {
	return formatTimesOfDay(schedule.timesOfDay, schedule.timesOfDayType);
}

export function formatScheduleDaysOfWeek(schedule: ConnectSchedule) {
	return formatDaysOfWeek(schedule.daysOfWeek);
}

export function formatScheduleStatus(status: ConnectScheduleStatus) {
	switch (status) {
		case 'active':
			return 'Active';
		case 'completed':
			return 'Completed';
		case 'paused':
			return 'Paused';
		default:
			throw new UnreachableError(status);
	}
}
