import { Route, useRouteMatch } from 'react-router-dom';
import {
	CommonRouter,
	HasAccountRoute,
	ProPageRoute,
	ScreenContainer,
	SlackIntegratedRoute,
} from '@common';
import { ConnectLayout } from '../components';
import { routes } from './routes';
import { Home } from '../pages/home';
import { CreateSchedule } from '../pages/create-schedule';
import { UpdateSchedule } from '../pages/update-schedule';
import { Explore } from '../pages/explore';
import { ViewPrompts } from '../pages/view-prompts';

export const ConnectAppRouter = () => {
	const homeRouteProps = {
		exact: true,
		path: routes.home.path,
	};
	const isHome = useRouteMatch(homeRouteProps);
	return (
		<ConnectLayout variant={isHome ? 'home' : 'page-header-and-logo'}>
			<CommonRouter
				public={
					<>
						<Route {...homeRouteProps}>
							<Home />
						</Route>
						<Route
							exact
							path={[
								routes.explore.path,
								routes.exploreActivity.path,
							]}
						>
							<Explore />
						</Route>
					</>
				}
				private={
					<>
						<ProPageRoute exact path={routes.scheduleActivity.path}>
							<SlackIntegratedRoute>
								<CreateSchedule />
							</SlackIntegratedRoute>
						</ProPageRoute>
						<HasAccountRoute exact path={routes.schedulesEdit.path}>
							<SlackIntegratedRoute>
								<UpdateSchedule />
							</SlackIntegratedRoute>
						</HasAccountRoute>
						<HasAccountRoute exact path={routes.viewPrompts.path}>
							<SlackIntegratedRoute>
								<ViewPrompts />
							</SlackIntegratedRoute>
						</HasAccountRoute>
					</>
				}
				ScreenContainer={ScreenContainer}
			/>
		</ConnectLayout>
	);
};
